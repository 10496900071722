import { colors } from 'theme';

export const contentContainerStyles = {
  padding: '0 0 8px 0',
  background: '#E5E5E5',
  marginTop: '24px',
  zIndex: 50,
  minHeight: '100vh',
};

export const containerStyles = {
  display: 'inherit',
  width: '100%',
  background: '#F6F6F6',
  minHeight: '100vh',
};

export const imageContainerStyles = {
  width: '100%',
  'text-align': 'center',
  marginBottom: '38px',
};

export const buttonsContainerStyles = {
  '@media screen and (min-width: 600px)': {
    display: 'inline-flex',
    justifyItems: 'end',
    justifyContent: 'flex-end',
  },
};

export const blockRightMarginOverrides = {
  marginRight: '-12px',
  '@media screen and (max-width: 1135px)': {
    marginRight: '0',
  },
};

export const bannerStyles = {
  Root: {
    style: {
      margin: '0',
      marginBottom: '24px',
      backgroundColor: colors.bannerInfo,
      width: 'auto',
    },
  },
};

export const expeditedShippingBlockStyles = {
  display: 'flex',
  '@media screen and (max-width: 1135px)': {
    display: 'block',
  },
};
