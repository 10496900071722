import { Value } from 'baseui/select';
import {
  addAddressInitialvalues,
  employeeDetailsInitialValues,
  employeePaymentDetailsInitialValues,
  paycardManagementLoadFundsInitialValues,
  paycardManagementOrderNewCardsInitialValues,
} from 'initialValues/PaycardsInitialValues';
import { OrganizationIDType } from './OrganizationTypes';
import { LocationIDType } from './LocationTypes';

export type EmployeeDetailsValuesType = typeof employeeDetailsInitialValues;
export type EmployeePaymentDetailsValuesType = typeof employeePaymentDetailsInitialValues;
export type OrderNewCardsValuesType = typeof paycardManagementOrderNewCardsInitialValues;
export type LoadFundsValuesType = typeof paycardManagementLoadFundsInitialValues;
export type AddAddressValuesType = typeof addAddressInitialvalues;

export type OrganizationPaycardPersonalizedCardProgramType = {
  enabled: boolean,
  programId: number
}

export type OrganizationPaycardNonPersonalizedCardProgramType = {
  enabled: boolean,
  fundingAccountId?: number
}

export type PaycardProviderType = {
  id: number,
  name: string
}

export type OrganizationPaycardSettingsParamsType = {
  organizationName: string,
  organizationExternalId: number,
  personalizedCardsProgram: OrganizationPaycardPersonalizedCardProgramType,
  nonPersonalizedCardsProgram: OrganizationPaycardNonPersonalizedCardProgramType,
  enableExpeditedShipping: boolean
}

export type OrganizationPaycardSettingsFormParamsType = {
  paycardCustomer: boolean,
  programId: Value
  externalIntegrationId: Value,
  cardManagement: boolean,
  fundingAccountId: Value,
  enableExpeditedShipping: boolean
}

export type SaveOrganizationPaycardSettingsFormParamsType = {
  paycardCustomer: boolean;
  programId: Value;
  externalIntegrationId: Value;
  organizationName: string;
  cardManagement: boolean;
  fundingAccountId: Value;
  enableExpeditedShipping: boolean;
};

export type FetchOrganizationsPaycardProviderParamsType = {
  parentId?: number
}

export type FetchOrganizationsPaycardOptionsParamsType = {
  providerOrganizationId: string
}

export type FetchOrganizationPaycardSettings = {
  organizationId: OrganizationIDType,
}

export type SetOrganizationPaycardSettingsParamsType = {
  organizationId: OrganizationIDType,
  data: OrganizationPaycardSettingsParamsType,
}

export type OrganizationPaycardProvidersType = {
  personalizedCardsPrograms: PaycardProviderType[],
  nonPersonalizedCardsPrograms: PaycardProviderType[]
}

export type FetchOrganizationMasterAccountPaycardBalanceParamsType = {
  organizationId: OrganizationIDType
}

export type OrganizationPaycardMasterAccountBalanceType = {
  currencyCode: string,
  availableBalance: number,
  ledgerBalance: number
}

export type FetchOrganizationPaycardsInventoryParamsType = {
  organizationId: OrganizationIDType,
  locationId?: LocationIDType,
  page?: number,
  pageSize?: number
}

export type FetChOrganizationPaycardsParamsType = {
  organizationId: OrganizationIDType,
  pageNum: number,
  pageSize: number,
  filter: string,
}

export type FetchOrganizationPaycardAccountParamsType = {
  organizationId: OrganizationIDType,
  proxyNumber: string,
}

export type CardStockAddress = {
  country: string,
  state: string,
  city: string,
  postalCode: string,
  addressLine1: string,
  addressLine2?: string
}

export type CardStockLocationItem = {
  locationId: number,
  locationName: string,
  locationAddress: CardStockAddress,
  availableCards: number,
  threshold: number,
  reorderAmount: number
}

export enum PaycardType {
  PERSONALIZED = 'PERSONALIZED',
  NON_PERSONALIZED = 'NON_PERSONALIZED'
}

export type PaycardItem = {
  type: PaycardType,
  registrationDate: string,
  locationName: string,
  firstName: string,
  lastName: string,
  payrollNumber: string,
  proxyNumber: string,
}

export type PaycardAccountDetails = {
  accountNumber: string,
  routingNumber: string,
}

export type OrganizationPaycardEligibleLocation = {
  id: number,
  name: string,
  nonPersonalizedCardsProgramEnabled: boolean
}

export type OrganizationPaycardRegisterCardSelectedLocationType = {
  id: number
  name: string
}

export type OrganizationPaycardProviderListType =
  OrganizationPaycardRegisterCardSelectedLocationType;

export enum EmployeeSearchKeyType {
  firstName = 'firstName',
  lastName = 'lastName',
  payrollNumber = 'payrollNumber',
}

export interface ValueItem {
  label: string,
  value: string
}

export type OrganizationPaycardRegisterCardEmployee = {
  firstName: string,
  lastName: string,
  mobileNumber: string,
  payrollId: string,
  address: string,
  unit: string,
  state: ValueItem[],
  city: string,
  birthDate: string,
  zip: string,
  ssn: string
}

export type DashAddressType = {
  country?: string,
  state: string,
  city: string,
  addressLine1: string,
  addressLine2?: string
  postalCode?: string
}

export type RegisterPaycardRequestParamsType = {
  proxyNumber: string,
  firstName: string,
  lastName: string,
  payrollNumber: string,
  dateOfBirth: string,
  ssn: string,
  phone: string,
  address: DashAddressType,
  useForDirectDeposit: boolean
}

export type LoadFundsRequestParamsType = {
  amount: number,
  currencyCode: string,
  description?: string,
  internalNotes?: string
}

export type RegisterPaycardParamsType = {
  organizationId: OrganizationIDType,
  locationId: LocationIDType,
  data: RegisterPaycardRequestParamsType,
}

export type LoadFundsParamsType = {
  organizationId: OrganizationIDType,
  proxyNumber: string,
  data: LoadFundsRequestParamsType,
}

export type PaymentDetailsType = {
  proxyNumber: string,
  funds?: number,
  paymentDescription?: string,
  internalNotes?: string,
  useForDirectDeposit: boolean
}

export type CardStockLocationPropsType = {
  location: CardStockLocationItem;
  setIsCardStockHistoryModalOpen: (isOpen: boolean, location: CardStockLocationItem) => void;
}

export type FetchOrdersHistoryParamsType = {
  organizationId: OrganizationIDType,
  locationId?: LocationIDType,
  page?: number,
  pageSize?: number,
  fromDate?: string,
  toDate?: string
}

export type HistoryOrderItem = {
  id: number,
  date?: string,
  status?: string,
  cardsCount: number,
  type: string
}

export type OrderNewCardsParamsType = {
  organizationId: OrganizationIDType,
  locationId: LocationIDType,
  data: OrderNewCardsType
}

export type OrderNewCardsType = {
  cardsCount: number,
  shipping: {
    addressee: string,
    attention: string,
    phone: string
  }
}

export type FetchManageFundsHistoryParamsType = {
  organizationId: OrganizationIDType,
  proxyNumber: string,
  page?: number,
  pageSize?: number,
  fromDate?: string,
  toDate?: string
}

export enum FundType {
  LOAD = 'LOAD',
  UNLOAD = 'UNLOAD'
}

export type FundsHistoryItem = {
  amount: number,
  type: FundType,
  transactionId?: string,
  date?: string,
  currencyCode?: string,
  description?: string,
  internalNotes?: string,
  isReversed?: boolean
}

export type UnloadFundsValuesType = {
  paymentDescription: string,
  internalNotes: string
}

export type UnloadFundsRequestParamsType = {
  transactionId: string,
  description?: string,
  internalNotes?: string
}

export type UnloadFundsParamsType = {
  organizationId: OrganizationIDType,
  proxyNumber: string,
  data: UnloadFundsRequestParamsType,
}

export type FetchShippingAddressesParamsType = {
  organizationId: OrganizationIDType,
  locationId: LocationIDType,
}

export type ShippingAddressItem = {
  id: number,
  addressee: string,
  attention: string,
  phone: string,
  address: {
    country: string,
    state: string,
    city: string,
    postalCode: string,
    addressLine1: string,
    addressLine2: string,
  },
  isDefault: boolean,
}

export type LocationLegalAddress = {
  country: string,
  state: string,
  city: string,
  postalCode: string,
  addressLine1: string,
  addressLine2: string,
  enableExpeditedShipping: boolean
}

export type SelectAddressValuesType = {
  selectedOption: string;
}

export type AddressType = {
  id?: string,
  addressee: string,
  attention: string,
  phone: string,
  address: {
    country: string,
    state: string,
    city: string,
    postalCode: string,
    addressLine1: string,
    addressLine2: string,
  },
  isDefault: boolean,
}

export type AddAddressParamsType = {
  organizationId: OrganizationIDType,
  locationId: LocationIDType,
  data: AddressType
}

export type DeleteAddressParamsType = {
  organizationId: OrganizationIDType,
  locationId: LocationIDType,
  addressId: string,
}

export type EditShippingAddressParamsType = {
  organizationId: OrganizationIDType,
  locationId: LocationIDType,
  addressId: string,
  data: AddressType
}
