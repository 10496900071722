import { Cell, Grid } from 'baseui/layout-grid';
import {
  LabelLarge, LabelMedium, ParagraphMedium, ParagraphSmall,
} from 'baseui/typography';
import AppInput from 'components/Form/AppInput';
import {
  FormikHandlers,
  FormikHelpers,
  FormikState,
  useFormik,
} from 'formik';
import {
  paycardManagementOrderNewCardsInitialValues,
} from 'initialValues/PaycardsInitialValues';
import { createContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  fetchShippingAddresses,
  paycardManagementSelectedCardStockSelector,
  paycardsEnableExpeditedShippingSelector,
  paycardsSelectedAddressSelector,
  paycardsShippingAddressesPendingSelector,
  setIsOrderNewCardsFormValid,
} from 'store/slices/paycards';
import { emptyPlaceholder } from 'theme';
import { OrderNewCardsValuesType } from 'types/PaycardManagementTypes';
import {
  orderNewCardsValidationSchema as validationSchema,
} from 'validation/paycardManagementSchema';
import { loggedOrganizationSelector } from 'store/slices/loggedOrganization';
import { Button, KIND, SIZE } from 'baseui/button';
import { Block } from 'baseui/block';
import Loader from 'components/Loader';
import checkIsModalOpen from 'utils/checkIsModalOpen';
import { ModalNames, modalsSelector, setModal } from 'store/slices/modals';
import { LABEL_PLACEMENT } from 'baseui/checkbox';
import AppCheckbox from 'components/Form/AppCheckbox';
import { Banner, HIERARCHY, KIND as BannerKind } from 'baseui/banner';
import OrderNewCardsSelectAddressModal from './OrderNewCardsSelectAddressModal/OrderNewCardsSelectAddressModal';
import OrderNewCardsAddAddressModal from './OrderNewCardsAddAddressModal/OrderNewCardsAddAddressModal';
import { bannerStyles, expeditedShippingBlockStyles } from '../../../PaycardManagementHelper';

export const OrderNewCardsOrderFormContext = createContext(
  {} as FormikState<OrderNewCardsValuesType> & FormikHelpers<OrderNewCardsValuesType> & FormikHandlers,
);

type Props = {
  formValues: OrderNewCardsValuesType | {},
  setFormValues: (values: OrderNewCardsValuesType | {}) => void,
}

const OrderNewCardsOrderSection = (
  {
    formValues,
    setFormValues,
  }: Props,
) => {
  const { t } = useTranslation(['common', 'paycardManagement']);
  const dispatch = useAppDispatch();
  const cardStock = useAppSelector(paycardManagementSelectedCardStockSelector);
  const organization = useAppSelector(loggedOrganizationSelector);
  const pending = useAppSelector(paycardsShippingAddressesPendingSelector);
  const modals = useAppSelector(modalsSelector);
  const paycardsSelectedAddress = useAppSelector(paycardsSelectedAddressSelector);
  const enableExpeditedShipping = useAppSelector(paycardsEnableExpeditedShippingSelector);

  const initialValues = {
    ...paycardManagementOrderNewCardsInitialValues,
    ...formValues,
  };

  const openSelectAddressModal = () => {
    if (paycardsSelectedAddress) {
      dispatch(setModal({
        name: ModalNames.SELECT_ADDRESS_MODAL,
        isOpen: true,
      }));
    } else {
      dispatch(setModal({
        name: ModalNames.ADD_NEW_ADDRESS_MODAL,
        isOpen: true,
      }));
    }
  };
  const formik = useFormik({
    initialValues,
    onSubmit: () => { },
    validationSchema,
  });

  const {
    values,
    isValid,
    validateForm,
    errors,
    touched,
    setValues,
  } = formik;

  const openAddNewAddressModal = () => {
    dispatch(setModal({
      name: ModalNames.SELECT_ADDRESS_MODAL,
      isOpen: false,
    }));
    dispatch(setModal({
      name: ModalNames.ADD_NEW_ADDRESS_MODAL,
      isOpen: true,
    }));
  };

  useEffect(() => {
    dispatch(setIsOrderNewCardsFormValid(isValid && paycardsSelectedAddress !== null));
  }, [isValid, paycardsSelectedAddress]);

  useEffect(() => {
    setFormValues(values);
  }, [values, setFormValues]);

  useEffect(() => {
    validateForm(initialValues);
  }, []);

  useEffect(() => {
    if (organization?.id && cardStock?.locationId) {
      dispatch(fetchShippingAddresses({
        organizationId: organization?.id,
        locationId: cardStock?.locationId.toString(),
      }));
    }
  }, [organization, cardStock]);

  return (
    <form>
      <OrderNewCardsOrderFormContext.Provider value={formik}>
        <Grid
          gridColumns={12}
          gridMargins={[12, 24]}
        >
          <LabelMedium margin="18px 18px 0 18px">
            {t('paycardManagement:paycardManagement.orderNewCards.cardAmountHeader')}
          </LabelMedium>
        </Grid>
        <Grid
          gridColumns={12}
          gridMargins={24}
          overrides={{
            Grid: {
              style: {
                flexDirection: 'column',
              },
            },
          }}
        >
          <AppInput
            name="cardsCount"
            inputProps={{
              id: 'cardsCount',
            }}
            formControlProps={{ htmlFor: 'cardsCount' }}
            cellSpan={[12, 6, 2.5]}
            context={OrderNewCardsOrderFormContext}
          />
          <Cell span={[12, 6, 2.5]}>
            <ParagraphSmall color="#757575" marginTop={0} hidden={!!errors.cardsCount && !!touched.cardsCount}>
              {`
                ${t('paycardManagement:paycardManagement.orderNewCards.currentStockLabel')}: ${cardStock?.availableCards}, 
                ${t('paycardManagement:paycardManagement.orderNewCards.thresholdLabel')}: ${cardStock?.threshold}
              `}
            </ParagraphSmall>
          </Cell>
        </Grid>
        <Grid
          gridColumns={12}
          gridMargins={[12, 24]}
          gridGaps={10}
        >
          <Block
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
          >
            <LabelLarge margin="18px">
              {t('paycardManagement:paycardManagement.orderNewCards.shippingInfoHeader')}
            </LabelLarge>
            <Button
              kind={KIND.tertiary}
              size={SIZE.compact}
              type="button"
              onClick={openSelectAddressModal}
              overrides={{
                BaseButton: {
                  props: {
                    id: 'PaycardManagementOrderNewCard-openSelectAdd',
                  },
                },
              }}
            >
              {t('common:change')}
            </Button>
          </Block>
        </Grid>
        <Grid
          gridColumns={12}
          gridMargins={24}
        >
          <Cell span={[12, 8, 4]}>
            {paycardsSelectedAddress && cardStock ? (
              <Block
                marginBottom="24px"
                display="flex"
                justifyContent="center"
                alignItems="flex-start"
                flexDirection="column"
              >
                <ParagraphMedium margin={0}>
                  {paycardsSelectedAddress?.attention || emptyPlaceholder}
                </ParagraphMedium>
                <ParagraphMedium margin={0}>
                  {paycardsSelectedAddress?.addressee || emptyPlaceholder}
                </ParagraphMedium>
                <ParagraphMedium margin={0}>
                  {`P: ${paycardsSelectedAddress?.phone}` || emptyPlaceholder}
                </ParagraphMedium>
                <ParagraphMedium margin={0}>
                  {`${paycardsSelectedAddress?.address?.addressLine1}${
                    paycardsSelectedAddress?.address?.addressLine2
                      ? `, ${paycardsSelectedAddress.address.addressLine2}`
                      : ''
                  }, ${paycardsSelectedAddress?.address?.city},
                  ${paycardsSelectedAddress?.address?.state},
                  ${paycardsSelectedAddress?.address?.postalCode}`
                  || emptyPlaceholder}
                </ParagraphMedium>
              </Block>
            )
              : (
                <Banner
                  kind={BannerKind.info}
                  hierarchy={HIERARCHY.low}
                  overrides={bannerStyles}
                >
                  <ParagraphSmall>
                    {t('paycardManagement:paycardManagement.orderNewCards.addShippingInformation')}
                  </ParagraphSmall>
                </Banner>
              )}
            {enableExpeditedShipping && (
              <Block
                marginBottom="24px"
                justifyContent="flex-start"
                alignItems="center"
                gridGap="24px"
                overrides={{
                  Block: {
                    style: expeditedShippingBlockStyles,
                  },
                }}
              >
                <AppCheckbox
                  name="standardShipping"
                  label={t('paycardManagement:paycardManagement.orderNewCards.standardShippingLabel')}
                  labelDescription={t('paycardManagement:paycardManagement.orderNewCards.standardShippingDescription')}
                  labelPlacement={LABEL_PLACEMENT.right}
                  cellSpan={12}
                  context={OrderNewCardsOrderFormContext}
                  cellProps={{
                    overrides: {
                      Cell: {
                        style: {
                          margin: '0px !important',
                          width: 'auto',
                        },
                      },
                    },
                  }}
                  checkboxProps={{
                    onChange: (event) => {
                      const isChecked = event.currentTarget.checked;
                      setValues({
                        ...values,
                        standardShipping: isChecked,
                        expeditedShipping: !isChecked,
                      });
                    },
                  }}
                />
                <AppCheckbox
                  name="expeditedShipping"
                  label={t('paycardManagement:paycardManagement.orderNewCards.expeditedShippingLabel')}
                  labelDescription={t('paycardManagement:paycardManagement.orderNewCards.expeditedShippingDescription')}
                  labelPlacement={LABEL_PLACEMENT.right}
                  cellSpan={12}
                  context={OrderNewCardsOrderFormContext}
                  cellProps={{
                    overrides: {
                      Cell: {
                        style: {
                          margin: '0px !important',
                        },
                      },
                    },
                  }}
                  checkboxProps={{
                    onChange: (event) => {
                      const isChecked = event.currentTarget.checked;
                      setValues({
                        ...values,
                        expeditedShipping: isChecked,
                        standardShipping: !isChecked,
                      });
                    },
                  }}
                />
              </Block>
            )}
            <Loader active={pending} />
          </Cell>
        </Grid>
        {checkIsModalOpen(modals, ModalNames.SELECT_ADDRESS_MODAL) && (<OrderNewCardsSelectAddressModal openAddAddressModal={openAddNewAddressModal} />)}
        {checkIsModalOpen(modals, ModalNames.ADD_NEW_ADDRESS_MODAL) && (<OrderNewCardsAddAddressModal />)}
      </OrderNewCardsOrderFormContext.Provider>
    </form>
  );
};

export default OrderNewCardsOrderSection;
