import {
  ALIGNMENT,
  Cell,
  Grid,
} from 'baseui/layout-grid';
import AppSelect from 'components/Form/AppSelect';
import Loader from 'components/Loader';
import {
  createContext,
  memo,
  useEffect,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  useFormik,
  FormikHandlers,
  FormikHelpers,
  FormikState,
} from 'formik';
import {
  useStyletron,
} from 'styletron-react';
import { PaycardManagementValuesType } from 'types/OrganizationTypes';
import { PaycardManagementInitialValues } from 'initialValues/OrganizationInitialValues';
import AppCheckbox from 'components/Form/AppCheckbox';
import {
  useAppDispatch,
  useAppSelector,
} from 'store/hooks';
import {
  paycardPersonalizedProgramsListSelector,
  paycardProviderOrganizationsListPendingSelector,
  paycardProviderOrganizationsListRejectedSelector,
  paycardProviderOrganizationsListSelector,
  paycardProgramsListPendingSelector,
  paycardProgramsListRejectedSelector,
  fetchOrganizationsPaycardProviderPrograms,
  paycardOrganizationSettingsPendingSelector,
  paycardOrganizationSettingsSelector,
  saveOrganizationPaycardSettings,
  fetchOrganizationsPaycardFundingAccounts,
  paycardFundingAccountIdsListRejectedSelector,
  paycardFundingAccountIdsListPendingSelector,
  paycardFundingAccountIdsListSelector,
  fetchOrganizationPaycardSettings,
} from 'store/slices/paycards';
import { Block } from 'baseui/block';
import {
  Button,
  KIND,
} from 'baseui/button';
import { useHistory } from 'react-router-dom';
import {
  fetchPaycardOrganizationSettingsMapper,
  savePaycardOrganizationSettingsMapper,
} from 'dataMappers/organizationsDataMappers';
import useIsFormChanged from 'hooks/useIsFormChanged';
import { organizationSelector } from 'store/slices/organizations';
import { errorSelector } from 'store/slices/error';
import { notificationSelector } from 'store/slices/notification';
import { PaycardManagementValidationSchema as validationSchema } from 'validation/addOrganizationSchema';
import { containerStyles, expeditedShippingDescriptionStyles } from '../OrganizationFormHelpers';

export const PaycardManagementFormContext = createContext({} as FormikState<PaycardManagementValuesType>
    & FormikHelpers<PaycardManagementValuesType>
    & FormikHandlers);

const OrganizationFormPaycardManagementSection = () => {
  const [css] = useStyletron();
  const { t } = useTranslation(['organizations', 'errors', 'common']);
  const history = useHistory();
  const dispatch = useAppDispatch();
  const paycardProviderOrganizationsList = useAppSelector(paycardProviderOrganizationsListSelector);
  const paycardPersonalizedProgramsList = useAppSelector(paycardPersonalizedProgramsListSelector);
  const paycardFundingAccountIdsList = useAppSelector(paycardFundingAccountIdsListSelector);
  const paycardOrganizationSettings = useAppSelector(paycardOrganizationSettingsSelector);
  const organization = useAppSelector(organizationSelector);
  const paycardProviderOrganizationsListPending = useAppSelector(paycardProviderOrganizationsListPendingSelector);
  const paycardProgramsListPending = useAppSelector(paycardProgramsListPendingSelector);
  const paycardFundingAccountIdsListPending = useAppSelector(paycardFundingAccountIdsListPendingSelector);
  const paycardOrganizationSettingsPending = useAppSelector(paycardOrganizationSettingsPendingSelector);
  const error = useAppSelector(errorSelector);
  const notificationToast = useAppSelector(notificationSelector);
  const paycardProviderOrganizationsListRejected = useAppSelector(paycardProviderOrganizationsListRejectedSelector);
  const paycardProgramsListRejected = useAppSelector(paycardProgramsListRejectedSelector);
  const paycardFundingAccountIdsRejected = useAppSelector(paycardFundingAccountIdsListRejectedSelector);

  const externalCustomerIdSampleOptions = paycardProviderOrganizationsList?.map((customer: any) => ({
    value: customer.id,
    label: customer.name,
  }));

  const programIdSampleOptions = useMemo(() => (
    paycardPersonalizedProgramsList?.map((program: any) => ({
      value: program.id,
      label: program.name || program.id,
    }))
  ), [paycardPersonalizedProgramsList]);

  const fundingAccountIdOptions = useMemo(() => (
    paycardFundingAccountIdsList?.map((account: any) => ({
      value: account.id,
      label: account.name || account.id,
    }))
  ), [paycardFundingAccountIdsList]);

  const initialValues = {
    ...PaycardManagementInitialValues,
    ...(paycardOrganizationSettings
      && fetchPaycardOrganizationSettingsMapper(
        paycardOrganizationSettings,
        paycardProgramsListPending,
        paycardOrganizationSettingsPending,
        paycardFundingAccountIdsListPending,
      )),
  };

  const onSubmit = (values: PaycardManagementValuesType) => {
    dispatch(saveOrganizationPaycardSettings({
      organizationId: organization?.id,
      data: savePaycardOrganizationSettingsMapper(
        {
          ...values,
          organizationName: organization?.name || '',
        },
      ),
    }))
      .then(() => {
        organization?.id && dispatch(fetchOrganizationPaycardSettings({ organizationId: organization?.id }));
      });
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  const {
    values,
    handleSubmit,
    isSubmitting,
    setValues,
    setFieldValue,
    setSubmitting,
    isValid,
  } = formik;

  useEffect(() => {
    if (error?.message || error?.messageKey) {
      setSubmitting(false);
    }
  }, [error]);

  const { isFormChanged, setDefaultValues, defaultData } = useIsFormChanged(values);

  const handleClickCancel = () => {
    history.push('/organizations');
  };

  useEffect(() => {
    if (notificationToast?.[1]?.isOpen) {
      setSubmitting(false);
      setDefaultValues(values);
    }
  }, [notificationToast]);

  useEffect(() => {
    if (paycardOrganizationSettings) {
      setDefaultValues(initialValues);
      setValues(initialValues);
    }
  }, [paycardOrganizationSettings]);

  useEffect(() => {
    if ((values?.externalIntegrationId?.[0]?.value
      || values.externalIntegrationId?.[0]?.value.toString() !== JSON.parse(defaultData)?.externalIntegrationId?.[0]?.value)
      && values?.paycardCustomer
    ) {
      dispatch(fetchOrganizationsPaycardProviderPrograms({ providerOrganizationId: values?.externalIntegrationId?.[0]?.value }));
    }
  }, [values.externalIntegrationId, values.paycardCustomer]);

  useEffect(() => {
    if ((values?.externalIntegrationId?.[0]?.value
      || values.externalIntegrationId?.[0]?.value.toString() !== JSON.parse(defaultData)?.externalIntegrationId?.[0]?.value)
      && values?.cardManagement
    ) {
      dispatch(fetchOrganizationsPaycardFundingAccounts({ providerOrganizationId: values?.externalIntegrationId?.[0]?.value }));
    }
  }, [values.externalIntegrationId, values.cardManagement]);

  useEffect(() => {
    if (values?.externalIntegrationId?.[0]?.value
      && values.externalIntegrationId?.[0]?.value.toString() !== JSON.parse(defaultData)?.externalIntegrationId?.[0]?.value) {
      setFieldValue('programId', null);
      setFieldValue('fundingAccountId', null);
    }
  }, [values.externalIntegrationId]);

  useEffect(() => {
    !values?.paycardCustomer
    && values.paycardCustomer === JSON.parse(defaultData)?.paycardCustomer
    && setFieldValue('programId', JSON.parse(defaultData).programId);
  }, [values.paycardCustomer]);

  return (
    <PaycardManagementFormContext.Provider value={formik}>
      <div className={css(containerStyles)}>
        <Loader active={paycardOrganizationSettingsPending} />
        <form onSubmit={handleSubmit}>
          <Grid
            gridColumns={12}
            align={ALIGNMENT.start}
          >
            <AppSelect
              showStar
              name="externalIntegrationId"
              label={t('organizations:paycardManagement.externalCustomerId.label')}
              placeholder={t('organizations:paycardManagement.externalCustomerId.placeholder')}
              options={externalCustomerIdSampleOptions}
              cellSpan={[12, 6, 3]}
              selectLoading={paycardProviderOrganizationsListPending}
              context={PaycardManagementFormContext}
              selectDisabled={paycardProviderOrganizationsListPending}
              noResultsText={paycardProviderOrganizationsListRejected ? t('errors:errorOnLoadingData') : ''}
            />
          </Grid>
          <Grid
            gridColumns={12}
            align={ALIGNMENT.start}
          >
            <AppCheckbox
              name="paycardCustomer"
              label={t('organizations:paycardManagement.paycardCustomer.label')}
              labelDescription={t('organizations:paycardManagement.paycardCustomer.description')}
              cellSpan={[12, 6, 2]}
              context={PaycardManagementFormContext}
              cellAlign={ALIGNMENT.center}
            />
          </Grid>
          {values.externalIntegrationId?.[0]?.value && values.paycardCustomer && (
          <Grid
            gridColumns={12}
            gridMargins={[75, 90, 120]}
            align={ALIGNMENT.start}
          >
            <AppSelect
              showStar
              name="programId"
              label={t('organizations:paycardManagement.programId.label')}
              placeholder={t('organizations:paycardManagement.programId.placeholder')}
              options={programIdSampleOptions}
              cellSpan={[12, 6, 3]}
              selectLoading={paycardProgramsListPending}
              context={PaycardManagementFormContext}
              selectDisabled={paycardProgramsListPending}
              noResultsText={paycardProgramsListRejected ? t('errors:errorOnLoadingData') : ''}
            />
          </Grid>
          )}
          <Grid
            gridColumns={12}
            align={ALIGNMENT.start}
          >
            <AppCheckbox
              name="cardManagement"
              label={t('organizations:paycardManagement.cardManagement.label')}
              labelDescription={t('organizations:paycardManagement.cardManagement.description')}
              cellSpan={[12, 6, 2]}
              context={PaycardManagementFormContext}
              cellAlign={ALIGNMENT.center}
            />
          </Grid>
          {values.externalIntegrationId?.[0]?.value && values.cardManagement && (
            <Grid
              gridColumns={12}
              gridMargins={[24, 90, 120]}
              align={ALIGNMENT.start}
            >
              <AppSelect
                showStar
                name="fundingAccountId"
                label={t('organizations:paycardManagement.fundingAccountId.label')}
                placeholder={t('organizations:paycardManagement.fundingAccountId.placeholder')}
                options={fundingAccountIdOptions}
                cellSpan={[12, 6, 3]}
                selectLoading={paycardFundingAccountIdsListPending}
                context={PaycardManagementFormContext}
                selectDisabled={paycardFundingAccountIdsListPending}
                noResultsText={paycardFundingAccountIdsRejected ? t('errors:errorOnLoadingData') : ''}
              />
            </Grid>
          )}
          {values.cardManagement && (
            <Grid
              gridColumns={12}
              gridMargins={[24, 90, 120]}
              align={ALIGNMENT.start}
            >
              <AppCheckbox
                name="enableExpeditedShipping"
                label={t('organizations:paycardManagement.expeditedShipping.label')}
                labelDescription={t('organizations:paycardManagement.expeditedShipping.description')}
                cellSpan={[12, 12, 12]}
                context={PaycardManagementFormContext}
                cellAlign={ALIGNMENT.center}
                paragraphProps={expeditedShippingDescriptionStyles}
                isLongLabel
                labelProps={{
                  whiteSpace: 'normal',
                }}
              />
            </Grid>
          )}
          <hr />
          <Block marginTop="24px">
            <Grid
              align={ALIGNMENT.start}
              gridColumns={12}
            >
              <Cell
                span={12}
              >
                <Block
                  display="flex"
                  justifyContent="flex-end"
                >
                  <Block
                    display="inline-flex"
                    marginRight="16px"
                  >
                    <Button
                      type="button"
                      kind={KIND.secondary}
                      onClick={handleClickCancel}
                      overrides={{
                        BaseButton: {
                          props: {
                            id: 'OrganizationFormPaycardManagementSection-cancel',
                          },
                        },
                      }}
                    >
                      {t('common:cancel')}
                    </Button>
                  </Block>

                  <Button
                    type="submit"
                    kind={KIND.primary}
                    onClick={() => setSubmitting(false)}
                    disabled={isSubmitting || !isFormChanged || !isValid}
                    overrides={{
                      BaseButton: {
                        props: {
                          id: 'OrganizationFormPaycardManagementSection-save',
                        },
                      },
                    }}
                  >
                    {t('common:save')}
                  </Button>
                </Block>
              </Cell>
            </Grid>
          </Block>
        </form>
      </div>
    </PaycardManagementFormContext.Provider>
  );
};

export default memo(OrganizationFormPaycardManagementSection);
