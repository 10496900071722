import {
  Cell,
  Grid,
} from 'baseui/layout-grid';
import {
  SIZE,
  TableBuilder,
  TableBuilderColumn,
} from 'baseui/table-semantic';
import {
  HeadingXSmall,
  ParagraphSmall,
} from 'baseui/typography';
import {
  memo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  useAppDispatch,
  useAppSelector,
} from 'store/hooks';
import {
  paycardPaycardManagementCardStockFetchFailedSelector,
  paycardPaycardManagementCardStockListPendingSelector,
  paycardPaycardManagementCardStockListSelector,
  paycardPaycardManagementCardStockPageNumberSelector,
  paycardPaycardManagementCardStockTotalPagesSelector,
} from 'store/slices/paycards';
import { CardStockLocationItem } from 'types/PaycardManagementTypes';
import { ReactComponent as NotFound } from 'assets/not-found.svg';
import { useStyletron } from 'styletron-react';
import { Pagination } from 'baseui/pagination';
import { paginationTransparentOverrides } from 'screens/CommonHelpers';
import { Block } from 'baseui/block';
import AppTooltip from 'components/Form/AppTooltip';
import { Skeleton } from 'baseui/skeleton';
import { imageContainerStyles } from 'screens/PaycardManagement/PaycardManagementHelper';
import {
  ModalNames,
  modalsSelector,
  setModal,
} from 'store/slices/modals';
import checkIsModalOpen from 'utils/checkIsModalOpen';
import CardStockActions from './CardStockActions/CardStockActions';
import PaycardMangementCardStockLocationStockHistoryModal
  from '../PaycardMangementCardStockLocationStockHistoryModal/PaycardMangementCardStockLocationStockHistoryModal';

export type PaycardManagementCardStockTablePropsType = {
  handlePageChange: ({ nextPage }: { nextPage: number }) => void,
}

const PaycardManagementCardStockTable = ({
  handlePageChange,
}: PaycardManagementCardStockTablePropsType) => {
  const { t } = useTranslation(['common', 'paycardManagement', 'dateFormats']);
  const dispatch = useAppDispatch();
  const cardStockList = useAppSelector(paycardPaycardManagementCardStockListSelector);
  const [css] = useStyletron();
  const numPages = useAppSelector(paycardPaycardManagementCardStockTotalPagesSelector);
  const page = useAppSelector(paycardPaycardManagementCardStockPageNumberSelector);
  const fetchFailed = useAppSelector(paycardPaycardManagementCardStockFetchFailedSelector);
  const pending = useAppSelector(paycardPaycardManagementCardStockListPendingSelector);
  const modals = useAppSelector(modalsSelector);
  const [selectedLocation, setSelectedLocation] = useState<CardStockLocationItem>();

  const setIsCardStockHistoryModalOpen = (
    isOpen: boolean,
    location: CardStockLocationItem,
  ) => {
    setSelectedLocation(location);
    dispatch(setModal({
      name: ModalNames.CARD_STOCK_VIEW_HISTORY,
      isOpen,
    }));
  };

  return (
    <Grid
      gridColumns={12}
      gridMargins={12}
    >
      <Cell
        span={12}
      >
        <TableBuilder
          data={cardStockList}
          size={SIZE.compact}
          emptyMessage={(
            <div className={css(imageContainerStyles)}>
              {fetchFailed
                ? (<HeadingXSmall margin={0}>{t('paycardManagement:paycardManagement.errorOnLoading')}</HeadingXSmall>)
                : (
                  <>
                    <NotFound />
                    <HeadingXSmall margin={0}>{t('paycardManagement:paycardManagement.cardStock.table.noResults')}</HeadingXSmall>
                  </>
                )}
            </div>
          )}
          isLoading={pending}
          loadingMessage={(
            <Skeleton
              height="72px"
              animation
            />
          )}
        >
          <TableBuilderColumn
            header={t('paycardManagement:paycardManagement.cardStock.table.locations')}
          >
            {(location: CardStockLocationItem) => (
              <ParagraphSmall>
                {location.locationName}
              </ParagraphSmall>
            )}
          </TableBuilderColumn>
          <TableBuilderColumn
            header={t('paycardManagement:paycardManagement.cardStock.table.address')}
          >
            {(location: CardStockLocationItem) => (
              <ParagraphSmall>
                {`${location.locationAddress.addressLine1},
                ${location.locationAddress.city},
                ${location.locationAddress.state},
                ${location.locationAddress.postalCode}`}
              </ParagraphSmall>
            )}
          </TableBuilderColumn>
          <TableBuilderColumn
            header={t('paycardManagement:paycardManagement.cardStock.table.availableCards')}
          >
            {(location: CardStockLocationItem) => (
              <ParagraphSmall>
                {location.availableCards}
              </ParagraphSmall>
            )}
          </TableBuilderColumn>
          <TableBuilderColumn
            header={(
              <>
                {t('paycardManagement:paycardManagement.cardStock.table.threshold')}
                <AppTooltip
                  content={t('paycardManagement:paycardManagement.cardStock.table.thresholdTooltip')}
                />
              </>
            )}
          >
            {(location: CardStockLocationItem) => (
              <ParagraphSmall>
                {location.threshold}
              </ParagraphSmall>
            )}
          </TableBuilderColumn>
          <TableBuilderColumn
            header={(
              <>
                {t('paycardManagement:paycardManagement.cardStock.table.reorderAmount')}
                <AppTooltip
                  content={t('paycardManagement:paycardManagement.cardStock.table.reorderAmountTooltip')}
                />
              </>
            )}
          >
            {(location: CardStockLocationItem) => (
              <ParagraphSmall>
                {location?.reorderAmount}
              </ParagraphSmall>
            )}
          </TableBuilderColumn>
          <TableBuilderColumn
            header={t('paycardManagement:paycardManagement.cardStock.table.actions')}
          >
            {(location: CardStockLocationItem) => (
              <CardStockActions
                location={location}
                setIsCardStockHistoryModalOpen={setIsCardStockHistoryModalOpen}
              />
            )}
          </TableBuilderColumn>
        </TableBuilder>

      </Cell>
      {!pending && (
      <Cell span={12}>
        <Block
          display="flex"
          width="100%"
          alignItems="center"
          justifyContent="center"
          justifyItems="center"
          marginBottom="16px"
        >
          <Pagination
            size={SIZE.compact}
            numPages={numPages}
            currentPage={page}
            overrides={paginationTransparentOverrides}
            onPageChange={handlePageChange}
          />
        </Block>
      </Cell>
      )}
      {checkIsModalOpen(modals, ModalNames.CARD_STOCK_VIEW_HISTORY) && <PaycardMangementCardStockLocationStockHistoryModal location={selectedLocation} />}
    </Grid>
  );
};

export default memo(PaycardManagementCardStockTable);
