import { createContext, useEffect, useState } from 'react';
import { StyleObject, useStyletron } from 'styletron-react';
import { useTranslation } from 'react-i18next';
import { ALIGNMENT, Cell, Grid } from 'baseui/layout-grid';
import Loader from 'components/Loader';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  FormikHandlers,
  FormikHelpers,
  FormikState,
  useFormik,
} from 'formik';
import {
  PayrollNumberFormatType,
  PayrollSettingsValuesType,
} from 'types/OrganizationTypes';
import {
  defaultConfigSelector,
  editOrganizationPayrollSettings,
  organizationConfigSelector,
  organizationSelector,
  organizationsPendingConfigsSelector,
} from 'store/slices/organizations';
import { payrollSettingsInitialValues } from 'initialValues/OrganizationInitialValues';
import {
  defaultConfigMapper,
  mapPayrollNumberFormat,
  mapRulesToReadableStrings,
  organizationConfigMapper,
  saveOrganizationPayrollSettingsConfigsMapper,
} from 'dataMappers/organizationsDataMappers';
import AppCheckbox from 'components/Form/AppCheckbox';
import { Block } from 'baseui/block';
import { Button, KIND } from 'baseui/button';
import { payrollSettingsValidationSchema as validationSchema } from 'validation/addOrganizationSchema';
import { useHistory } from 'react-router-dom';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ParagraphSmall } from 'baseui/typography';
import { ModalNames, modalsSelector, setModal } from 'store/slices/modals';
import checkIsModalOpen from 'utils/checkIsModalOpen';
import { notificationSelector } from 'store/slices/notification';
import { errorSelector } from 'store/slices/error';
import useIsFormChanged from 'hooks/useIsFormChanged';
import OrganizationFormPayrollNumberFormatModal from './OrganizationFormPayrollNumberFormatModal/OrganizationFormPayrollNumberFormatModal';
import { listStyles } from '../OrganizationFormHelpers';

export const PayrollSettingsFormContext = createContext(
  {} as FormikState<PayrollSettingsValuesType> & FormikHelpers<PayrollSettingsValuesType> & FormikHandlers,
);

const containerStyles = {
  width: '100%',
  position: 'relative',
  overflow: 'hidden',
  zIndex: 10,
  margin: '24px 0',
} as StyleObject;

const OrganizationFormPayrollSettingsSection = () => {
  const dispatch = useAppDispatch();
  const [css] = useStyletron();
  const { t } = useTranslation(['organizations', 'errors', 'common']);
  const history = useHistory();
  const organization = useAppSelector(organizationSelector);
  const pending = useAppSelector(organizationsPendingConfigsSelector);
  const notificationToast = useAppSelector(notificationSelector);
  const organizationConfig = useAppSelector(organizationConfigSelector);
  const defaultConfig = useAppSelector(defaultConfigSelector);
  const modals = useAppSelector(modalsSelector);
  const error = useAppSelector(errorSelector);
  const [rules, setRules] = useState<string[] | null>(null);

  const initialValues = {
    ...payrollSettingsInitialValues,
    ...(defaultConfig && defaultConfigMapper(defaultConfig)),
    ...(organizationConfig && organizationConfigMapper(organizationConfig)),
  };

  const onSubmit = (
    values: PayrollSettingsValuesType,
  ) => {
    dispatch(
      editOrganizationPayrollSettings({
        organizationID: organization?.id,
        configs: saveOrganizationPayrollSettingsConfigsMapper(values),
      }),
    );
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const {
    values,
    handleSubmit,
    isSubmitting,
    setSubmitting,
    setValues,
    isValid,
    setFieldValue,
  } = formik;

  const { isFormChanged, setDefaultValues } = useIsFormChanged(values);

  const handleClickCancel = () => {
    history.push('/organizations');
  };

  const openRulesModal = () => {
    dispatch(setModal({
      name: ModalNames.PAYROLL_NUMBER_FORMAT_MODAL,
      isOpen: true,
    }));
  };

  const savePayrollNumberFormat = (payrollNumberFormatValues: PayrollNumberFormatType) => {
    setFieldValue('payrollNumberFormat', payrollNumberFormatValues);
    const readableStrings = mapRulesToReadableStrings(payrollNumberFormatValues, t);
    setRules(readableStrings);
  };

  const setRulesFromValues = (formValues: PayrollSettingsValuesType) => {
    const payrollNumberFormaFieldValue = mapPayrollNumberFormat(formValues.payrollNumberFormat);
    if (payrollNumberFormaFieldValue) {
      const readableStrings = mapRulesToReadableStrings(payrollNumberFormaFieldValue, t);
      setRules(readableStrings);
    }
  };

  useEffect(() => {
    if (organizationConfig) {
      setValues(initialValues);
      setDefaultValues(initialValues);
      setRulesFromValues(initialValues);
    }
  }, [organizationConfig]);

  useEffect(() => {
    if (notificationToast?.[1]?.isOpen) {
      setSubmitting(false);
    }
  }, [notificationToast]);

  useEffect(() => {
    if (error?.message || error?.messageKey) {
      setSubmitting(false);
    }
  }, [error]);

  return (
    <PayrollSettingsFormContext.Provider value={formik}>
      <div className={css(containerStyles)}>
        <Loader active={pending} />
        <form onSubmit={handleSubmit}>
          <Grid
            align={ALIGNMENT.center}
            gridColumns={12}
            gridGutters={16}
            overrides={{
              Grid: {
                style: {
                  margin: '24px 0 !important',
                },
              },
            }}
          >
            <Cell
              span={[12, 3]}
              align={ALIGNMENT.start}
            >
              <AppCheckbox
                name="payrollSettingsEnabled"
                label={t('organizations:payrollSettings.enablePayrollNumberValidation')}
                cellSpan={[12, 6, 3]}
                context={PayrollSettingsFormContext}
                cellAlign={ALIGNMENT.center}
                tooltipTitle={t('organizations:payrollSettings.enablePayrollNumberValidation')}
                tooltipContent={t('organizations:payrollSettings.enablePayrollNumberValidationTooltip')}
                checkboxProps={{
                  onChange: (option) => {
                    const isChecked = option.currentTarget.checked;
                    setValues({
                      ...values,
                      payrollSettingsEnabled: isChecked,
                    });
                    savePayrollNumberFormat(isChecked ? values.payrollNumberFormat : {});
                  },
                }}
                formControlProps={{
                  overrides: {
                    ControlContainer: {
                      style: {
                        marginBottom: '0',
                      },
                    },
                  },
                }}
              />
              <Block
                display="flex"
                flexDirection="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                gridGap="4px"
                paddingLeft="20%"
              >
                {(values.payrollSettingsEnabled && rules && rules.length > 0) && (
                  <Block>
                    <ul className={css(listStyles)}>
                      {rules.map((rule: string) => (
                        <li key={rule}>
                          <ParagraphSmall>
                            {rule}
                          </ParagraphSmall>
                        </li>
                      ))}
                    </ul>
                  </Block>
                )}
                {values.payrollSettingsEnabled && (
                  <Button
                    type="button"
                    style={{ padding: '0', height: '36px' }}
                    kind={KIND.tertiary}
                    onClick={openRulesModal}
                    overrides={{
                      BaseButton: {
                        props: {
                          id: 'OrganizationFormProfileSection-openRulesModal',
                        },
                      },
                    }}
                  >
                    <Block
                      display="inline-flex"
                      justifyContent="flex-start"
                      alignItems="center"
                      gridGap="12px"
                    >
                      <FontAwesomeIcon icon={faPencil} />
                      {(rules && rules.length > 0)
                        ? t('organizations:payrollSettings.editRules')
                        : t('organizations:payrollSettings.addRules')}
                    </Block>
                  </Button>
                )}
              </Block>
            </Cell>
          </Grid>
          <hr />
          <Block marginTop="24px">
            <Grid
              gridColumns={12}
              align={ALIGNMENT.center}
            >
              <Cell
                span={9}
                align={ALIGNMENT.end}
              />

              <Cell
                span={3}
                align={ALIGNMENT.end}
              >
                <Block
                  display="flex"
                  justifyContent="flex-end"
                >
                  <Block
                    display="inline-flex"
                    marginRight="16px"
                  >
                    <Button
                      type="button"
                      kind={KIND.secondary}
                      onClick={handleClickCancel}
                      overrides={{
                        BaseButton: {
                          props: {
                            id: 'OrganizationFormProfileSection-cancel',
                          },
                        },
                      }}
                    >
                      {t('common:cancel')}
                    </Button>
                  </Block>
                  <Button
                    type="submit"
                    kind={KIND.primary}
                    disabled={isSubmitting || !isFormChanged || !isValid}
                    overrides={{
                      BaseButton: {
                        props: {
                          id: 'OrganizationFormProfileSection-save',
                        },
                      },
                    }}
                  >
                    {t('common:save')}
                  </Button>
                </Block>
              </Cell>
            </Grid>
          </Block>
        </form>
      </div>
      {checkIsModalOpen(modals, ModalNames.PAYROLL_NUMBER_FORMAT_MODAL)
       && (
       <OrganizationFormPayrollNumberFormatModal
         savePayrollNumberFormat={savePayrollNumberFormat}
         payrollNumberFormatFieldValue={values.payrollNumberFormat}
       />
       )}
    </PayrollSettingsFormContext.Provider>
  );
};

export default OrganizationFormPayrollSettingsSection;
