import { createContext, useEffect } from 'react';
import {
  FormikHandlers, FormikHelpers, FormikState, useFormik,
} from 'formik';
import { useTranslation } from 'react-i18next';
import { Layer } from 'baseui/layer';
import { LabelMedium, LabelSmall, ParagraphMedium } from 'baseui/typography';
import { Cell, Grid } from 'baseui/layout-grid';
import { STYLE_TYPE } from 'baseui/checkbox';
import { ModalNames, modalsSelector, setModal } from 'store/slices/modals';
import { PayrollNumberFormatType, PayrollNumberFormatValuesType } from 'types/OrganizationTypes';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import checkIsModalOpen from 'utils/checkIsModalOpen';
import { PayrollNumberFormatInitialValues } from 'initialValues/OrganizationInitialValues';
import AppModal from 'components/AppModal/AppModal';
import {
  payrollNumberFormatValidationSchema as validationSchema,
} from 'validation/addOrganizationSchema';
import AppCheckbox from 'components/Form/AppCheckbox';
import { Block } from 'baseui/block';
import { colors } from 'theme';
import useIsFormChanged from 'hooks/useIsFormChanged';
import AppInput from 'components/Form/AppInput';
import Loader from 'components/Loader';
import { payrollNumberFormatDataMapper, payrollNumberFormatFieldsDataMapper } from 'dataMappers/organizationsDataMappers';

export const PayrollNumberFormatContext = createContext(
  {} as FormikState<PayrollNumberFormatValuesType> & FormikHelpers<PayrollNumberFormatValuesType> & FormikHandlers,
);

interface Props {
  savePayrollNumberFormat: (values: PayrollNumberFormatType) => void;
  payrollNumberFormatFieldValue?: PayrollNumberFormatType | null;
}

const OrganizationFormPayrollNumberFormatModal = ({
  savePayrollNumberFormat,
  payrollNumberFormatFieldValue,
}: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['organizations', 'errors', 'common']);
  const modals = useAppSelector(modalsSelector);
  const isModalOpen = checkIsModalOpen(modals, ModalNames.PAYROLL_NUMBER_FORMAT_MODAL);

  const initialValues: PayrollNumberFormatValuesType = {
    ...PayrollNumberFormatInitialValues,
    ...(payrollNumberFormatFieldValue ? payrollNumberFormatFieldsDataMapper(payrollNumberFormatFieldValue) : {}),
  };

  const onSubmit = (
    values: PayrollNumberFormatValuesType,
  ) => {
    const data = payrollNumberFormatDataMapper(values) as PayrollNumberFormatType;
    data && savePayrollNumberFormat(data);
    dispatch(setModal({
      name: ModalNames.PAYROLL_NUMBER_FORMAT_MODAL,
      isOpen: false,
    }));
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  const {
    values,
    handleSubmit,
    resetForm,
    validateForm,
    isSubmitting,
    setSubmitting,
    setValues,
    isValid,
    setErrors,
    errors,
  } = formik;

  const { isFormChanged, setDefaultValues } = useIsFormChanged(values);

  const handleModalClose = () => {
    resetForm();
  };

  const handleSubmitExtended = () => {
    validateForm(values)
      .then((err) => {
        if (Object.keys(err).length === 0 && err.constructor === Object) {
          handleSubmit();
        }
      });
  };

  useEffect(() => {
    if (isModalOpen) {
      setSubmitting(false);
      setValues({
        ...initialValues,
      });
      setDefaultValues({
        ...initialValues,
      });
    }
  }, [isModalOpen]);

  useEffect(() => {
    if (errors) {
      setErrors({
        ...errors,
      });
    }
  }, [values, errors]);

  const isAlphaNumericSelected = values.alphaNumeric;
  const isNumericSelected = values.numeric;
  const isAlphaSelected = values.alpha;

  return (
    <Layer index={400}>
      <PayrollNumberFormatContext.Provider value={formik}>
        <form>
          <AppModal
            modalWidth={['90vw', '90vw', '50vw', '35vw']}
            modal={ModalNames.PAYROLL_NUMBER_FORMAT_MODAL}
            title={t('organizations:payrollNumberFormatModal.title')}
            cancelBtnText={t('common:cancel')}
            onClose={handleModalClose}
            actionBtnText={t('common:save')}
            onAction={handleSubmitExtended}
            isCloseDisabled={isSubmitting}
            isActionDisabled={isSubmitting || !isFormChanged || !isValid}
            minWidth="35vw"
          >
            <Loader active={isSubmitting} />
            <Block>
              <Grid
                gridColumns={12}
                gridMargins={16}
                gridGaps={16}
              >
                <Cell span={12}>
                  <Block>
                    <ParagraphMedium
                      margin="24px 0 8px 0"
                      color={colors.grayText}
                      style={{ whiteSpace: 'normal' }}
                    >
                      {t('organizations:payrollNumberFormatModal.subtitle1')}
                      <span style={{ fontWeight: '700' }}>{t('organizations:payrollNumberFormatModal.subtitle2')}</span>
                      {t('organizations:payrollNumberFormatModal.subtitle3')}
                    </ParagraphMedium>
                  </Block>
                </Cell>
              </Grid>
            </Block>
            <AppCheckbox
              name="alphaNumeric"
              label={(
                <Block
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-start"
                  height="24px"
                  gridGap="4px"
                >
                  <LabelMedium>
                    {t('organizations:payrollNumberFormatModal.alphanumeric')}
                  </LabelMedium>
                  <LabelMedium color={colors.grayText}>
                    {t('organizations:payrollNumberFormatModal.alphanumericDescription')}
                  </LabelMedium>
                </Block>
              )}
              checkboxType={STYLE_TYPE.default}
              cellSpan={[12]}
              context={PayrollNumberFormatContext}
              disabled={isNumericSelected || isAlphaSelected}
            />
            <AppCheckbox
              name="numeric"
              label={(
                <Block
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-start"
                  height="24px"
                  gridGap="4px"
                >
                  <LabelMedium>
                    {t('organizations:payrollNumberFormatModal.numeric')}
                  </LabelMedium>
                  <LabelMedium color={colors.grayText}>
                    {t('organizations:payrollNumberFormatModal.numericDescription')}
                  </LabelMedium>
                </Block>
              )}
              checkboxType={STYLE_TYPE.default}
              cellSpan={[12]}
              context={PayrollNumberFormatContext}
              disabled={isAlphaNumericSelected || isAlphaSelected}
            />
            <AppCheckbox
              name="alpha"
              label={(
                <Block
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-start"
                  height="24px"
                  gridGap="4px"
                >
                  <LabelMedium>
                    {t('organizations:payrollNumberFormatModal.alpha')}
                  </LabelMedium>
                  <LabelMedium color={colors.grayText}>
                    {t('organizations:payrollNumberFormatModal.alphaDescription')}
                  </LabelMedium>
                </Block>
              )}
              checkboxType={STYLE_TYPE.default}
              cellSpan={[12]}
              context={PayrollNumberFormatContext}
              disabled={isAlphaNumericSelected || isNumericSelected}
            />
            <Block>
              <AppCheckbox
                name="charsLength"
                label={(
                  <Block
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-start"
                    height="24px"
                    gridGap="4px"
                  >
                    <LabelMedium>
                      {t('organizations:payrollNumberFormatModal.setLength')}
                    </LabelMedium>
                  </Block>
              )}
                checkboxType={STYLE_TYPE.default}
                cellSpan={[12]}
                context={PayrollNumberFormatContext}
              />
              {values.charsLength && (
                <Block
                  style={{
                    paddingLeft: '32px',
                  }}
                >
                  <AppInput
                    name="charsLengthValue"
                    label={(
                      <Block
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-start"
                        height="auto"
                      >
                        <LabelSmall
                          style={{
                            whiteSpace: 'normal',
                            textOverflow: 'unset',
                          }}
                        >
                          {t('organizations:payrollNumberFormatModal.enterASingleDigit')}
                        </LabelSmall>
                      </Block>
                    )}
                    placeholder={t('organizations:payrollNumberFormatModal.enterASingleDigitPlaceholder')}
                    cellSpan={[12, 6, 6]}
                    context={PayrollNumberFormatContext}
                  />
                </Block>
              )}
            </Block>
            <Block>
              <AppCheckbox
                name="startsWith"
                disabled={!values.alphaNumeric && !values.numeric && !values.alpha}
                label={(
                  <Block
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-start"
                    height="24px"
                  >
                    <LabelMedium>
                      {t('organizations:payrollNumberFormatModal.alwaysStartsWith')}
                    </LabelMedium>
                  </Block>
                )}
                checkboxType={STYLE_TYPE.default}
                cellSpan={[12]}
                context={PayrollNumberFormatContext}
              />
              {values.startsWith && (
                <Block
                  style={{
                    paddingLeft: '32px',
                  }}
                >
                  <AppInput
                    name="startsWithValue"
                    label={(
                      <Block
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-start"
                        height="auto"
                      >
                        <LabelSmall
                          style={{
                            whiteSpace: 'normal',
                            textOverflow: 'unset',
                          }}
                        >
                          {t('organizations:payrollNumberFormatModal.enter1OrMoreCharacters')}
                        </LabelSmall>
                      </Block>
                    )}
                    placeholder={t('organizations:payrollNumberFormatModal.enter1OrMoreCharactersPlaceholder')}
                    cellSpan={[12, 6, 6]}
                    context={PayrollNumberFormatContext}
                  />
                </Block>
              )}
            </Block>

          </AppModal>
        </form>
      </PayrollNumberFormatContext.Provider>
    </Layer>
  );
};

export default OrganizationFormPayrollNumberFormatModal;
