import { createContext, useEffect } from 'react';
import {
  FormikHandlers, FormikHelpers, FormikState, useFormik,
} from 'formik';
import { useTranslation } from 'react-i18next';
import { Layer } from 'baseui/layer';
import { Block } from 'baseui/block';
import { Cell, Grid } from 'baseui/layout-grid';
import { ParagraphMedium } from 'baseui/typography';
import { SelectAddressValuesType, ShippingAddressItem } from 'types/PaycardManagementTypes';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectAddressValidationSchema as validationSchema } from 'validation/paycardManagementSchema';
import checkIsModalOpen from 'utils/checkIsModalOpen';
import { ModalNames, modalsSelector, setModal } from 'store/slices/modals';
import AppModal from 'components/AppModal/AppModal';
import useIsFormChanged from 'hooks/useIsFormChanged';
import {
  deleteShippingAddress,
  fetchShippingAddresses,
  paycardManagementSelectedCardStockSelector,
  paycardsSelectedAddressSelector, paycardsShippingAddressesSelector, setEditAddress, setSelectedAddress,
} from 'store/slices/paycards';
import { colors, emptyPlaceholder } from 'theme';
import { Radio, RadioGroup } from 'baseui/radio';
import Loader from 'components/Loader';
import { Button, KIND, SIZE } from 'baseui/button';
import { loggedOrganizationSelector } from 'store/slices/loggedOrganization';
import { editShippingAddressDataMapper } from 'dataMappers/paycardManagementMapper';

export const SelectAddressContext = createContext(
  {} as FormikState<SelectAddressValuesType> & FormikHelpers<SelectAddressValuesType> & FormikHandlers,
);

type Props = {
  openAddAddressModal: () => void,
}

const OrderNewCardsSelectAddressModal = ({ openAddAddressModal }: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['common', 'paycardManagement', 'organizations']);
  const modals = useAppSelector(modalsSelector);
  const isModalOpen = checkIsModalOpen(modals, ModalNames.SELECT_ADDRESS_MODAL);
  const shippingAddresses = useAppSelector(paycardsShippingAddressesSelector);
  const paycardsSelectedAddress = useAppSelector(paycardsSelectedAddressSelector);
  const organization = useAppSelector(loggedOrganizationSelector);
  const cardStock = useAppSelector(paycardManagementSelectedCardStockSelector);

  const initialValues: SelectAddressValuesType = {
    selectedOption: paycardsSelectedAddress ? `${paycardsSelectedAddress.id}` : '',
  };

  const onSubmit = (
    values: SelectAddressValuesType,
  ) => {
    const selectedAddress = shippingAddresses.find((address: ShippingAddressItem) => address.id === parseInt(values.selectedOption, 10));
    dispatch(setSelectedAddress(selectedAddress));
    dispatch(setModal({
      name: ModalNames.SELECT_ADDRESS_MODAL,
      isOpen: false,
    }));
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  const {
    values,
    handleSubmit,
    resetForm,
    validateForm,
    isSubmitting,
    setSubmitting,
    setValues,
    isValid,
    setErrors,
    errors,
    setFieldValue,
  } = formik;

  const { isFormChanged, setDefaultValues } = useIsFormChanged(values);

  const handleModalClose = () => {
    resetForm();
  };

  const handleSubmitExtended = () => {
    validateForm(values)
      .then((err) => {
        if (Object.keys(err).length === 0 && err.constructor === Object) {
          handleSubmit();
        }
      });
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const deleteAddress = (address: ShippingAddressItem) => {
    if (
      organization?.id
      && cardStock?.locationId
      && address
    ) {
      dispatch(deleteShippingAddress({
        organizationId: organization?.id,
        locationId: cardStock?.locationId.toString(),
        addressId: `${address.id}`,
      }))
        .then(() => {
          dispatch(fetchShippingAddresses({
            organizationId: organization?.id,
            locationId: cardStock?.locationId.toString(),
          }));
        });
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const editAddress = (address: ShippingAddressItem) => {
    if (
      organization?.id
      && cardStock?.locationId
      && address
    ) {
      const data = editShippingAddressDataMapper(address);
      dispatch(setEditAddress(data));
      dispatch(setModal({
        name: ModalNames.SELECT_ADDRESS_MODAL,
        isOpen: false,
      }));
      dispatch(setModal({
        name: ModalNames.ADD_NEW_ADDRESS_MODAL,
        isOpen: true,
      }));
    }
  };

  useEffect(() => {
    if (isModalOpen) {
      setSubmitting(false);
      setValues({
        ...initialValues,
      });
      setDefaultValues({
        ...initialValues,
      });
    }
  }, [isModalOpen]);

  useEffect(() => {
    if (errors) {
      setErrors({
        ...errors,
      });
    }
  }, [values, errors]);

  return (
    <Layer index={400}>
      <SelectAddressContext.Provider value={formik}>
        <form>
          <AppModal
            modalWidth={['90vw', '90vw', '50vw', '35vw']}
            modal={ModalNames.SELECT_ADDRESS_MODAL}
            title={t('paycardManagement:paycardManagement.selectAddressModal.title')}
            cancelBtnText={t('common:cancel')}
            onClose={handleModalClose}
            actionBtnText={t('common:save')}
            onAction={handleSubmitExtended}
            isCloseDisabled={isSubmitting}
            isActionDisabled={isSubmitting || !isFormChanged || !isValid}
            minWidth="35vw"
          >
            <Loader active={isSubmitting} />
            <Block>
              <Grid
                gridColumns={12}
                gridMargins={16}
                gridGaps={16}
              >
                <Cell
                  span={12}
                  gridGaps={24}
                >
                  <RadioGroup
                    name="selectedOption"
                    value={values.selectedOption}
                    onChange={(e) => setFieldValue('selectedOption', e.currentTarget.value)}
                    error={!!(errors.selectedOption && errors.selectedOption)}
                  >
                    {shippingAddresses && shippingAddresses?.map((address: ShippingAddressItem) => (

                      <Radio
                        value={`${address.id}`}
                        key={address.id}
                        overrides={{
                          Root: {
                            style: {
                              justifyContent: 'flex-start',
                              display: 'flex',
                              alignItems: 'flex-start',
                              gap: '10px',
                              width: '100%',
                            },
                          },
                          Label: () => (
                            <Block
                              display="flex"
                              alignItems="flex-start"
                              justifyContent="space-between"
                              gridGap="16px"
                              flexDirection="row"
                              width="100%"
                            >
                              <Block
                                display="flex"
                                alignItems="flex-start"
                                justifyContent="flex-start"
                                gridGap="4px"
                                flexDirection="column"
                                margin-left="76px"
                              >
                                <ParagraphMedium margin={0}>
                                  {`${address.attention} ${address.isDefault ? `(${t('common:default')})` : ''}`}
                                </ParagraphMedium>
                                <ParagraphMedium margin={0} color={colors.grayText}>
                                  {address?.addressee || emptyPlaceholder}
                                </ParagraphMedium>
                                <ParagraphMedium margin={0} color={colors.grayText}>
                                  {`P: ${address?.phone}` || emptyPlaceholder}
                                </ParagraphMedium>
                                <ParagraphMedium margin={0} color={colors.grayText}>
                                  {`${address?.address?.addressLine1}${
                                    address?.address?.addressLine2
                                      ? `, ${address.address.addressLine2}`
                                      : ''
                                  }, ${address?.address?.city},
                                    ${address?.address?.state},
                                    ${address?.address?.postalCode}`
                                    || emptyPlaceholder}
                                </ParagraphMedium>
                              </Block>
                              {/* <Block>
                                <Button
                                  overrides={{
                                    Root: {
                                      props: {
                                        id: 'SelectAddressModal-edit',
                                      },
                                    },
                                  }}
                                  kind={KIND.tertiary}
                                  size={SIZE.compact}
                                  onClick={() => editAddress(address)}
                                >
                                  {t('common:edit')}
                                </Button>
                                <Button
                                  overrides={{
                                    Root: {
                                      props: {
                                        id: 'SelectAddressModal-delete',
                                      },
                                    },
                                  }}
                                  kind={KIND.tertiary}
                                  size={SIZE.compact}
                                  onClick={() => deleteAddress(address)}
                                >
                                  {t('common:delete')}
                                </Button>
                              </Block> */}
                            </Block>
                          ),
                        }}
                      />
                    ))}
                  </RadioGroup>

                </Cell>
                {shippingAddresses.length < 3 && (
                  <Cell
                    span={12}
                    gridGaps={24}
                  >
                    <Button
                      size={SIZE.compact}
                      kind={KIND.secondary}
                      onClick={() => openAddAddressModal()}
                    >
                      {t('paycardManagement:paycardManagement.selectAddressModal.addNewAddress')}
                    </Button>
                  </Cell>
                )}
              </Grid>
            </Block>
          </AppModal>
        </form>
      </SelectAddressContext.Provider>
    </Layer>

  );
};

export default OrderNewCardsSelectAddressModal;
