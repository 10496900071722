import { Value } from 'baseui/select';

export const employeeDetailsInitialValues = {
  payrollId: '',
  firstName: '',
  lastName: '',
  birthDate: '',
  ssn: '',
  mobileNumber: '',
  address: '',
  unit: '',
  city: '',
  state: [{ value: '' }],
  zip: '',
};

export const employeePaymentDetailsInitialValues = {
  funds: '',
  proxyNumber: '',
  paymentDescription: '',
  internalNotes: '',
  useForDirectDeposit: false,
};

export const paycardManagementOrderNewCardsInitialValues = {
  cardsCount: '',
  standardShipping: true,
  expeditedShipping: false,
};

export const paycardManagementLoadFundsInitialValues = {
  amount: '',
  paymentDescription: '',
  internalNotes: '',
};

export const addAddressInitialvalues = {
  addressee: '',
  attention: '',
  phone: '',
  state: [] as Value,
  city: '',
  postalCode: '',
  addressLine1: '',
  unit: '',
  isDefault: false,
};
