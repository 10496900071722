import * as Yup from 'yup';
import {
  commonAmountValidationSchema,
  commonPercentValidationSchema,
  commonPositiveAmountValidationSchema,
} from './commonSchema';
import messages from './messages';

export const profileValidationSchema = Yup.object().shape({
  externalId: Yup.string().max(255, messages?.maxLength),
  netSuiteId: Yup.string().max(32, messages?.maxLength),
  salesForceId: Yup.string().max(32, messages?.maxLength),
  deductionCode: Yup.string().max(64, messages?.maxLength),
  externalIntegrationId: Yup.string().max(128, messages?.maxLength),
  externalPayrollSystemId: Yup.string().max(64, messages?.maxLength),
  name: Yup.string().trim().max(255, messages?.orgNameLength)
    .matches(/^[a-zA-Z0-9,.'[\]()\s-]*$/, messages?.orgNameSupportedChars)
    .required(messages?.required),
  streetAddress: Yup.string().max(600, messages?.maxLength),
  city: Yup.string().max(255, messages?.maxLength),
  state: Yup.string().max(255, messages?.maxLength),
  zipCode: Yup.string().max(12, messages?.maxLength),
  programStatus: Yup.array().of(Yup.object().shape(
    {
      value: Yup.string().max(255, messages?.maxLength).required(messages?.required),
      label: Yup.string().max(255, messages?.maxLength),
    },
  )).required(messages?.required),
  payrollPeriodStartDay: Yup.array().of(Yup.object().shape(
    {
      value: Yup.string().max(255, messages?.maxLength).required(messages?.required),
      label: Yup.string().max(32, messages?.maxLength),
    },
  )).required(messages?.required),
  contacts: Yup.array().of(Yup.object().shape(
    {
      contactName: Yup.string().max(255, messages?.maxLength),
      contactPhone: Yup.string().max(32, messages?.maxLength),
      contactAddress: Yup.string().max(600, messages?.maxLength),
    },
  )),
  batchDeliveryEmail: Yup.boolean(),
  organizationBatchDeliveryEmailAddresses: Yup.array().when(['automaticBatchGeneration', 'batchDeliveryEmail'], {
    is: (automaticBatchGeneration: boolean, batchDeliveryEmail: boolean) => automaticBatchGeneration && batchDeliveryEmail,
    // eslint-disable-next-line func-names
    then: Yup.array().typeError(messages?.requiredEmails).transform(function (value, originalValue) {
      if (this.isType(value) && value !== null) {
        return value;
      }
      return originalValue ? originalValue.split(/,\s?/) : '';
    })
      .of(Yup.string().required(messages?.requiredEmails).email(messages?.multipleEmail))
      .default(null)
      .nullable(),
  }).nullable(),
});

export const employeeValidationSchema = Yup.object().shape({
  enrollmentStatus: Yup.array().of(Yup.object().shape(
    {
      value: Yup.string().max(64, messages?.maxLength),
      label: Yup.string().max(255, messages?.maxLength),
    },
  ).required()),
  lowerEarningsThreshold: commonPositiveAmountValidationSchema().required(messages?.numberRequired),
  offerPercentPerEmployee: commonPercentValidationSchema(),
  tpoOfferPercentPerEmployee: commonPercentValidationSchema(),
});

export const employeeProfileValidationSchema = Yup.object().shape({
  enrollmentStatus: Yup.array().of(Yup.object().shape(
    {
      value: Yup.string().max(64, messages?.maxLength),
      label: Yup.string().max(255, messages?.maxLength),
    },
  ).required()),
});

export const zendeskTicketValidationSchema = Yup.object().shape({
  ticketStatuses: Yup.array().of(Yup.object().shape(
    {
      value: Yup.string().max(64, messages?.maxLength),
      label: Yup.string().max(255, messages?.maxLength),
    },
  )),
  ticketNumber: Yup.string().max(255, messages?.maxLength),
});

export const locationValidationSchema = Yup.object().shape({
  locationName: Yup.string().max(255, messages?.maxLength).required(messages?.required),
  locationExternalID: Yup.string().max(128, messages?.maxLength).required(messages?.required),
  externalIntegrationID: Yup.string().max(128, messages?.maxLength),
  organizationTimezone: Yup.string().max(255, messages?.maxLength).required(messages?.required),
  businessDayStartTime: Yup.date().required(messages?.required),
  tipsProviderId: Yup.string().max(128, messages?.maxLength),
  tag: Yup.array().of(Yup.object().shape(
    {
      value: Yup.string().max(255, messages?.maxLength).required(messages?.required),
      label: Yup.string().max(32, messages?.maxLength),
    },
  )).required(messages?.required),
  payGroup: Yup.array().of(Yup.object().shape(
    {
      value: Yup.string().max(255, messages?.maxLength),
      label: Yup.string().max(255, messages?.maxLength),
    },
  )),
  storeNumber: Yup.string().max(128, messages?.maxLength).matches(/^[\d]*$/, messages?.positiveNumbersOnly),
  alphaNumericStoreNumber: Yup.string().max(128, messages?.maxLength).matches(/^[a-zA-Z0-9]*$/, messages?.mustBeLetterOrNumber),
  conceptID: Yup.string().max(128, messages?.maxLength).matches(/^[\d]*$/, messages?.positiveNumbersOnly),
  tpoMaxEarningsPerBusinessDay: commonPositiveAmountValidationSchema().required(messages?.numberRequired),
  tpoMaxEarningsPerPeriod: commonPositiveAmountValidationSchema().required(messages?.numberRequired),
  jobCodeExclusionsValue: Yup.string().when('jobCodeExclusions', {
    is: true,
    then: Yup.string().typeError(messages?.required).max(8000, messages?.maxLength).required(messages?.required),
    otherwise: Yup.string().nullable(),
  }),
});

export const assignLocationsValidationSchema = Yup.object().shape({
  relations: Yup.array().of(Yup.object()).required(messages?.required),
});

export const payGroupValidationSchema = Yup.object().shape({
  payrollCutoffTime: Yup
    .date()
    .required(messages?.required)
    .test(
      'payrollCutoffTimeCondition',
      messages.cutoffTimeEarlierThanEwaAndTPOCutoffTime,
      function payrollCutoffTimeCondition(value) {
        const {
          ewaCutoffTime, tpoCutoffTime, ewaCutoffDayOffset, payrollCutoffDayOffset, tpoCutoffDayOffset,
        } = this.parent;

        if (
          value
        && ewaCutoffTime
        && tpoCutoffTime
        && ewaCutoffDayOffset?.[0]?.value
        && payrollCutoffDayOffset?.[0]?.value
        && tpoCutoffDayOffset?.[0]?.value
        ) {
          const isValid = ((new Date(ewaCutoffTime) >= new Date(value)
            && ewaCutoffDayOffset[0].value >= payrollCutoffDayOffset[0].value)
          || (new Date(tpoCutoffTime) >= new Date(value)
            && tpoCutoffDayOffset[0].value >= payrollCutoffDayOffset[0].value));

          return !isValid;
        }

        return true;
      },
    ),
  payGroupName: Yup.string().max(255, messages?.maxLength).required(messages?.required),
  organizationTimezone: Yup.string().max(255, messages?.maxLength).required(messages?.required),
  externalIntegrationCode: Yup.string().max(64, messages?.maxLength),
  deductionCode: Yup.string().max(64, messages?.maxLength),
  businessDayStartTime: Yup.date()
    .required(messages?.required)
    .test(
      'businessStartDateCondition',
      messages.businessDayStartTimeEarlierThanEwaAndTPOCutoffTime,
      function businessStartDateCondition(value) {
        const { ewaCutoffTime, tpoCutoffTime } = this.parent;

        if (value && ewaCutoffTime && tpoCutoffTime) {
          const isValid = new Date(value) >= new Date(ewaCutoffTime)
          || new Date(value) >= new Date(tpoCutoffTime);

          return !isValid;
        }

        return true;
      },
    ),
  ewaCutoffTime: Yup
    .date()
    .required(messages?.required),
  ewaCutoffDayOffset: Yup.array().of(Yup.object().shape(
    {
      value: Yup.string().max(32, messages?.maxLength).required(messages?.required),
      label: Yup.string().max(255, messages?.maxLength),
    },
  )).required(messages?.required),
  tpoCutoffTime: Yup
    .date()
    .required(messages?.required),
  tpoCutoffDayOffset: Yup.array().of(Yup.object().shape(
    {
      value: Yup.string().max(32, messages?.maxLength).required(messages?.required),
      label: Yup.string().max(255, messages?.maxLength),
    },
  )).required(messages?.required),
  payCycle: Yup.array().of(Yup.object().shape(
    {
      value: Yup.string().max(255, messages?.maxLength).required(messages?.required),
      label: Yup.string().max(32, messages?.maxLength),
    },
  )).required(messages?.required),
  payrollPeriodStartDay: Yup.array().of(Yup.object().shape(
    {
      value: Yup.string().max(255, messages?.maxLength).required(messages?.required),
      label: Yup.string().max(32, messages?.maxLength),
    },
  )).required(messages?.required),
  paydayOffset: Yup.array().of(Yup.object().shape(
    {
      value: Yup.string().max(32, messages?.maxLength).required(messages?.required),
      label: Yup.string().max(255, messages?.maxLength),
    },
  )).required(messages?.required),
  payrollCutoffDayOffset: Yup.array()
    .of(
      Yup.object().shape({
        value: Yup.string().max(32, messages?.maxLength).required(messages?.required),
        label: Yup.string().max(255, messages?.maxLength),
      }),
    )
    .required(messages?.required)
    .test(
      'payrollCutoffDayOffsetCondition',
      messages.cutoffDayOffsetEarlierThanEwaAndTPOCutoffDayOffset,
      function payrollCutoffDayOffsetCondition(value) {
        const { ewaCutoffDayOffset, tpoCutoffDayOffset } = this.parent;
        if (
          value?.[0]?.value
        && ewaCutoffDayOffset?.[0]?.value
        && tpoCutoffDayOffset?.[0]?.value
        ) {
          const isValid = ewaCutoffDayOffset[0].value > value[0].value
          || tpoCutoffDayOffset[0].value > value[0].value;

          return !isValid;
        }

        return true;
      },
    ),
  batchDeliveryEmailAddresses: Yup.array()
    // eslint-disable-next-line func-names
    .transform(function (value, originalValue) {
      if (this.isType(value) && value !== null) {
        return value;
      }
      return originalValue ? originalValue.split(/,\s?/) : [];
    })
    .of(Yup.string().required(messages?.requiredEmails).email(messages?.multipleEmail)),
});

export const EWASettingsValidationSchema = Yup.object().shape({
  offerPercentPerEmployee: commonPercentValidationSchema(),
  maxShiftLength: Yup
    .number()
    .typeError(messages.mustBeANumber)
    .min(0, messages.positiveNumbersOnly)
    .max(24, messages?.maxLength)
    .required(messages?.numberRequired),
  maxOfferTotal: commonPositiveAmountValidationSchema().required(messages?.numberRequired),
  salaryEmployees: Yup.boolean().required(messages?.required),
  autoClockOutTime: Yup.date().required(messages?.required),
  excludeTippedShifts: Yup.boolean().required(messages?.required),
  ewaIncludeCCTips: Yup.boolean().required(messages?.required),
  ewaIncludeCashTips: Yup.boolean().required(messages?.required),
  excludeAutoClockedOutShifts: Yup.boolean().required(messages?.required),
  ewaIncludeServiceCharges: Yup.boolean().required(messages?.required),
  ewaMaxHourlyRateTimecard: commonPositiveAmountValidationSchema().required(messages?.numberRequired),
  ewaMaxHourlyRateSalariedEmployees: commonPositiveAmountValidationSchema().required(messages?.numberRequired),
});

export const TPOSettingsValidationSchema = Yup.object().shape({
  tpoPercent: commonPercentValidationSchema(),
  tpoFeeEmployeePerPeriodValue: Yup
    .string().when('tpoEnabled', {
      is: true,
      then:
        Yup.string().when('tpoFeeEmployeePerPeriod', {
          is: true,
          then: commonAmountValidationSchema(),
        }),
    }),
  tpoFuegoCardPaymentValue: commonPositiveAmountValidationSchema().required(messages?.numberRequired),
  tpoAchPaymentFeeValue: commonPositiveAmountValidationSchema().required(messages?.numberRequired),
  tpoSameDayAchPaymentFeeValue: commonPositiveAmountValidationSchema().required(messages?.numberRequired),
  tpoPushToCardPaymentFeeValue: commonPositiveAmountValidationSchema().required(messages?.numberRequired),
  tpoGroupBatchesByBankAccountOrPayGroup: Yup.boolean().required(messages?.required),
  tpoSendAutomaticAchRequests: Yup.boolean().required(messages?.required),
  tpoDailyPayoutTime: Yup.string().when(['tpoDailyPayoutSchedule', 'tpoEnabled'], {
    is: (tpoDailyPayoutSchedule: any, tpoEnabled: boolean) => tpoDailyPayoutSchedule[0].value !== 'API' && tpoEnabled,
    then: Yup.string().required(messages?.required),
    otherwise: Yup.string().nullable(),
  }),
});

export const DirectDepositSettingsValidationSchema = Yup.object().shape({
  fddTermsAndConditions: Yup
    .string()
    .when('directDepositSetting', {
      is: (directDepositSetting: any) => directDepositSetting[0].value === 'DIRECT_DEPOSIT_FUEGO',
      then: Yup.string()
        .max(1024, `${messages?.maxLength} - 1024 characters.`)
        .matches(/^[A-Za-z0-9\s%#@\\^\\&№,.!?;()":'_\\r\\n-]*$/, messages?.ddText)
        .nullable(),
      otherwise: Yup.string()
        .nullable(),
    }),
  ddCustomMessage: Yup
    .string()
    .when('directDepositSetting', {
      is: (directDepositSetting: any) => directDepositSetting[0].value === 'DIRECT_DEPOSIT_CUSTOM',
      then: Yup.string()
        .max(1024, `${messages?.maxLength} - 1024 characters.`)
        .matches(/^[A-Za-z0-9\s%#@\\^\\&№,.!?;()":'_\\r\\n-]*$/, messages?.ddText)
        .required(messages?.required),
      otherwise: Yup.string().nullable(),
    }),
  automaticFDDReportEmailRecipients: Yup
    .array()
    .when(['directDepositSetting', 'automaticFDDReportEnabled'], {
    // eslint-disable-next-line max-len
      is: (directDepositSetting: any, automaticFDDReportEnabled: boolean) => directDepositSetting[0].value === 'DIRECT_DEPOSIT_FUEGO' && automaticFDDReportEnabled,
      // eslint-disable-next-line func-names
      then: Yup.array().typeError(messages?.requiredEmails).transform(function (value, originalValue) {
        if (this.isType(value) && value !== null) {
          return value;
        }
        return originalValue ? originalValue.split(/,\s?/) : '';
      })
        .of(Yup.string().required(messages?.requiredEmails).email(messages?.multipleEmail))
        .default(null),
      otherwise: Yup.array().nullable(),
    }),
  bankDetailsUpdatesEnabled: Yup.boolean(),
  bankDetailsUpdatesCustomerCanonicalId: Yup.string()
    .max(50, messages?.maxLength)
    .matches(/^[a-zA-Z0-9-]*$/, messages?.errorLettersDigitsAndDash)
    .when(['directDepositSetting', 'bankDetailsUpdatesEnabled'], {
      is: (directDepositSetting: any, bankDetailsUpdatesEnabled: boolean) => (
        directDepositSetting[0].value === 'DIRECT_DEPOSIT_FUEGO' && bankDetailsUpdatesEnabled
      ),
      then: Yup.string().required(messages?.required),
      otherwise: Yup.string().nullable(),
    }),
  bankDetailsUpdatesLegalEntityIdentifier: Yup.string()
    .max(50, messages?.maxLength)
    .matches(/^[a-zA-Z0-9-]*$/, messages?.errorLettersDigitsAndDash)
    .when(['directDepositSetting', 'bankDetailsUpdatesEnabled'], {
      is: (directDepositSetting: any, bankDetailsUpdatesEnabled: boolean) => (
        directDepositSetting[0].value === 'DIRECT_DEPOSIT_FUEGO' && bankDetailsUpdatesEnabled
      ),
      then: Yup.string().required(messages?.required),
      otherwise: Yup.string().nullable(),
    }),
  bankDetailsUpdatesPayrollSystemInstance: Yup.array().of(
    Yup.object().shape({
      value: Yup.string()
        .max(50, messages?.maxLength),
      label: Yup.string()
        .max(255, messages?.maxLength),
    }),
  )
    .when(['directDepositSetting', 'bankDetailsUpdatesEnabled'], {
      is: (directDepositSetting: any, bankDetailsUpdatesEnabled: boolean) => directDepositSetting[0]?.value === 'DIRECT_DEPOSIT_FUEGO'
      && bankDetailsUpdatesEnabled,
      then: Yup.array().of(
        Yup.object().shape({
          value: Yup.string()
            .max(50, messages?.maxLength),
          label: Yup.string()
            .max(255, messages?.maxLength),
        }),
      )
        .test(
          'required',
          messages?.required,
          (value) => {
            if (value && value.length > 0) {
              const isValid = value.every((item) => item.value && item.label);
              return isValid;
            }
            return false;
          },
        ),
      otherwise: Yup.array().nullable(),
    }),
});

export const PaycardManagementValidationSchema = Yup.object().shape({
  externalIntegrationId: Yup.array()
    .of(
      Yup.object().shape({
        value: Yup.string()
          .max(255, messages?.maxLength)
          .required(messages?.required),
        label: Yup.string().max(255, messages?.maxLength),
      }),
    )
    .required(messages?.required),
  programId: Yup.array()
    .when('paycardCustomer', {
      is: true,
      then: Yup.array()
        .typeError(messages?.required)
        .of(
          Yup.object().shape({
            value: Yup.string()
              .max(255, messages?.maxLength)
              .required(messages?.required),
            label: Yup.string().max(255, messages?.maxLength),
          }),
        )
        .required(messages?.required)
        .min(1, messages.required),
    })
    .nullable(),
  fundingAccountId: Yup.array()
    .when('cardManagement', {
      is: true,
      then: Yup.array()
        .typeError(messages?.required)
        .of(
          Yup.object().shape({
            value: Yup.string()
              .max(255, messages?.maxLength)
              .required(messages?.required),
            label: Yup.string().max(255, messages?.maxLength),
          }),
        )
        .required(messages?.required)
        .min(1, messages.required),
    })
    .nullable(),
});

export const BankAccountValidationSchema = Yup.object().shape({
  organizationName: Yup.string()
    .max(255, messages?.maxLength)
    .required(messages?.required),
  routingNumber: Yup.string()
    .matches(/^\d{9}$/, messages?.mustBeValidRoutingNumber)
    .required(messages?.required),
  accountNumber: Yup.string()
    .matches(/^\d{4,17}$/, messages?.mustBeValidAccountNumber)
    .required(messages?.required),
  legalName: Yup.string()
    .min(5, messages?.minLength)
    .max(255, messages?.maxLength)
    .required(messages?.required),
  nickname: Yup.string().max(64, messages?.maxLength),
  repaymentStatement: Yup.string().required(messages?.required).max(140, messages?.maxLength),
});

export const LocationPaycardConfigValidationSchema = Yup.object().shape({
  addressLine1: Yup.string()
    .required(messages?.required)
    .max(50, messages?.maxLength)
    .matches(/^[A-Za-z0-9 %#@\\^\\&№,.!'-]*$/, messages?.address),
  city: Yup.string()
    .required(messages?.required)
    .max(50, messages?.maxLength)
    .min(3, messages?.minLength)
    .matches(/^[A-Za-z '-]*$/, messages?.city),
  state: Yup.array()
    .of(
      Yup.object().shape({
        value: Yup.string()
          .max(2, messages?.maxLength)
          .required(messages?.required),
        label: Yup.string()
          .max(2, messages?.maxLength),
      }),
    )
    .required(messages?.required)
    .min(1, messages?.required),
  postalCode: Yup.string()
    .required(messages?.required)
    .max(12, messages?.maxLength)
    .matches(/^\d{5}(-\d{4})?$/, messages?.zipCode),
  programId: Yup.array()
    .of(
      Yup.object().shape({
        value: Yup.string()
          .max(255, messages?.maxLength)
          .required(messages?.required),
        label: Yup.string().max(255, messages?.maxLength),
      }),
    )
    .required(messages?.required)
    .min(1, messages?.required),
  locationExternalId: Yup.array()
    .of(
      Yup.object().shape({
        value: Yup.string()
          .max(255, messages?.maxLength)
          .required(messages?.required),
        label: Yup.string().max(255, messages?.maxLength),
      }),
    )
    .required(messages?.required)
    .min(1, messages?.required),
});

export const payrollSettingsValidationSchema = Yup.object().shape({
  payrollSettingsEnabled: Yup.boolean().required(messages?.required),
  payrollNumberFormat: Yup.object().nullable(),
});

export const payrollNumberFormatValidationSchema = Yup.object().shape({
  alphaNumeric: Yup.boolean().required(messages?.required),
  numeric: Yup.boolean().required(messages?.required),
  alpha: Yup.boolean().required(messages?.required),
  charsLength: Yup.boolean().required(messages?.required),
  startsWith: Yup.boolean().required(messages?.required),
  charsLengthValue: Yup.string()
    .when('charsLength', {
      is: true,
      then: Yup.string()
        .required(messages?.required)
        .matches(
          /^(?:\d{1,3}|\d{1,3}-\d{1,3})$/,
          messages?.invalidCharsLengthValue,
        )
        .test(
          'valid-range',
          messages?.invalidCharsLengthRange,
          (value) => {
            if (!value) return true;
            if (!value.includes('-')) return true;

            const [min, max] = value.split('-').map(Number);
            return min >= 1 && max <= 128 && max >= min;
          },
        ),
      otherwise: Yup.string().notRequired(),
    }),
  startsWithValue: Yup.string()
    .when(['startsWith', 'alphaNumeric', 'numeric', 'alpha', 'charsLengthValue'], {
      is: (
        startsWith: string,
        alphaNumeric: boolean,
        numeric: boolean,
        alpha: boolean,
      ) => startsWith && (alphaNumeric || numeric || alpha),
      then: Yup.string()
        .required(messages?.required)
        .test(
          'valid-characters',
          messages?.invalidStartsWithValue,
          (value, context) => {
            const { alphaNumeric, numeric, alpha } = context.parent;
            if (!value) return false;

            const alphaNumericRegex = /^[0-9a-zA-Z,]+$/;
            const numericRegex = /^[0-9,]+$/;
            const alphaRegex = /^[a-zA-Z,]+$/;

            if (alphaNumeric && !alphaNumericRegex.test(value)) {
              return context.createError({
                message: messages?.onlyAlphanumeric,
              });
            }

            if (numeric && !numericRegex.test(value)) {
              return context.createError({
                message: messages?.onlyNumbers,
              });
            }

            if (alpha && !alphaRegex.test(value)) {
              return context.createError({
                message: messages?.onlyLetters,
              });
            }

            return true;
          },
        )
        .test(
          'valid-length',
          messages?.invalidStartsWithLength,
          (value, context) => {
            const { charsLength, charsLengthValue } = context.parent;

            // Only perform length validation if charsLength is true and charsLengthValue is not empty
            if (charsLength && charsLengthValue) {
              if (!value) return true;

              // Split value based on commas if present, else treat as a single string
              const splitValue = value.includes(',') ? value.split(',') : [value];

              let maxChars = Number(charsLengthValue); // assume charsLengthValue is a single digit or a range

              if (charsLengthValue.includes('-')) {
                // If it's a range (e.g., '3-5'), parse it
                const [, max] = charsLengthValue.split('-').map(Number);
                maxChars = max;
              }

              // Ensure value length is between 1 and maxChars
              if (value.length < 1 || value.length >= maxChars) {
                return false;
              }

              // Ensure every string in splitValue is within the length range
              return splitValue.every((entry) => entry.length < maxChars);
            }

            // If charsLength is false or charsLengthValue is empty, skip length validation
            return true;
          },
        ),
      otherwise: Yup.string().notRequired(),
    }),
});
