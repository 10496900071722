import { WorkersDetailsUpdateValuesType } from '../types/WorkerTypes';

const WorkersDetailsUpdateModalInitialValues: WorkersDetailsUpdateValuesType = {
  selectedField: [{ value: '', label: '' }],
  email: '',
  otp: ['', '', '', ''],
  firstName: '',
  lastName: '',
};

export default WorkersDetailsUpdateModalInitialValues;
