import {
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit';
import {
  editEmployeeDetailsRequest,
  fetchEmployeeRequest,
  fetchEmployeeDrawsRequest,
  fetchEmploymentPayrollPeriodsRequest,
  fetchEmployeeConfigsRequest,
  editEmployeeConfigsRequest,
  searchEmployeesRequest,
  editEmploymentWorkersRequest,
  fetchWorkerEmploymentLinksRequest,
  createACFRequest,
  createBadDebtRequest,
  fetchEmploymentPayRatesRequest,
  editEmploymentPayRatesRequest,
  declineDrawRequest,
  voidDrawRequest,
} from 'api/employmentsAPI';
import {
  fetchDefaultConfigRequest,
  saveZendeskTicketRequest,
} from 'api/api';
import {
  createDeductionBatchesRequest,
} from 'api/batchesAPI';
import {
  fetchLocationsRequest,
} from 'api/locationsAPI';
import { fetchPayGroupsRequest } from 'api/payGroupsAPI';
import {
  OrganizationConfigResponseType,
  GetEmployeeConfigsParamsType,
  FetchEmployeeDetailsDrawsParamsType,
  CreateBatchesPropsType,
  FetchByEmploymentPayrollPeriodParamsType,
  OrganizationFormPropsType,
  GetEmployeePayRatesParamsType,
  FetchEmployeePaycardOfferParamsType,
  ChangeEmployeePaycardOfferParamsType,
} from 'types/OrganizationTypes';
import {
  AssignEmployeesParamsType,
  Draw,
  Employee,
  EmployeeResponseType,
  EmploymentPayroll,
  EditEmployeeDetailsParamsType,
  SearchOrganizationEmploymentsParamsType,
  ZendeskTicketParamsType,
  OperationMode,
  CreateACFParamTypes,
  EditEmployeeDetailsProfileParamsType,
  DirectDepositEnrollmentType,
  PayRatesResponseType,
  EditEmploymentPayRatesParamsType,
  DeclineDrawRequestParamsType,
  VoidDrawRequestParamsType,
} from 'types/EmployeeTypes';
import {
  resetLinkEmploymentsFormEvent,
  resetEmployeeDetailsWorkersDrawsAndOffers,
} from 'store/events';
import {
  fetchEmployeesOfferByIdRequest,
  fetchEmployeesOffersRequest,
  fetchEmployeesTPOOffersRequest,
} from 'api/offersAPI';
import {
  FetchEmployeeOfferParamsType,
  WorkerEmploymentLinkParamsType,
} from 'types/WorkerTypes';
import {
  Offer,
  TPOOffer,
} from 'types/OfferTypes';
import { AccessUnit } from 'components/Access/Access';
import { SearchDropDown } from 'types/CommonTypes';
import {
  fetchEnrollmentByWorkerIdRequest,
  generateDirectDepositReportRequest,
} from 'directDepositApi/organizationEnrollments';
import {
  GenerateDirectDepositReportParamsType,
  GetDirectDepositEnrollmentByWorkerIdType,
} from 'types/DirectDepositTypes';
import { employmentStatuses } from 'screens/Employees/EmployeesHelpers';
import {
  changeWorkerPaycardOfferRequest,
  fetchWorkerPaycardOfferRequest,
} from 'bankingApi/paycardAPI';
import { RootState } from '..';
import { fetchWorkerAccountExternal } from './workerAccount';
import { setLoggedOrganization } from './loggedOrganization';
import { fetchWorker } from './workers';

export interface DirectDepositReportData {
  id?: string
  name?: string
  csv?: string
}

export const initialState = {
  list: [] as Employee[],
  pendingList: false,
  pageNumber: 1 as number,
  numPages: 1 as number,
  pageSize: 50 as number,
  totalSize: 0 as number,
  searchResults: [] as Employee[],
  searchPageNumber: 1 as number,
  searchPageSize: 50 as number,
  searchTotalSize: 0 as number,
  searchNumPages: 1 as number,
  pendingSearchList: false,
  fetchEmployeesFailed: false,
  specifiedPageSize: 50 as number,
  employeeDetails: {
    configsAndDetailsSaved: false,
    pending: false,
    details: undefined as EmployeeResponseType | undefined,
    configs: [] as OrganizationConfigResponseType[] | null,
    employeeTypeChanged: false,
  },
  employeeOffers: {
    offer: undefined as Offer | undefined,
    generatedOffer: undefined as Offer | undefined,
    pendingList: false,
    areFetched: false,
  },
  employeeTPOOffers: {
    generatedOffer: undefined as TPOOffer | undefined,
    pendingList: false,
    areFetched: false,
  },
  employeeEWADraws: {
    list: [] as Draw[],
    pendingList: false,
    pendingZendeskTicketForm: false,
    zendeskTicketSaved: false,
    areListFetched: false,
    pageNumber: 1 as number,
    pageSize: 50 as number,
    totalSize: 0 as number,
    numPages: 1 as number,
  },
  employeeTPODraws: {
    list: [] as Draw[],
    pendingList: false,
    areListFetched: false,
    pageNumber: 1 as number,
    pageSize: 50 as number,
    totalSize: 0 as number,
    numPages: 1 as number,
  },
  employment: {
    payrollPeriods: [] as EmploymentPayroll[],
    deductionBatchesSaved: false,
    pending: false,
    pageNumber: 1 as number,
    pageSize: 50 as number,
    totalSize: 0 as number,
    numPages: 1 as number,
  },
  workerLinking: {
    pageNumber: 1 as number,
    pageSize: 50 as number,
    totalSize: 0 as number,
    numPages: 1 as number,
    list: [] as any[], // TODO: describe what is returned here
    pendingList: false,
  },
  employeeSearch: {
    searchPattern: '',
    searchEnrollmentStatus: [] as SearchDropDown[],
    searchEmploymentStatus: [
      employmentStatuses.find((status) => status.id === 'ACTIVE'),
    ] as SearchDropDown[],
    searchPayGroup: [] as SearchDropDown[],
    searchLocation: [] as SearchDropDown[],
    searchPayrollNumber: '',
    filterCriteria: [{ id: 'name', name: '' }],
  },
  directDepositReportData: {
    pending: false,
    file: null as DirectDepositReportData | null,
  },
  directDepositEnrollment: undefined as DirectDepositEnrollmentType | undefined,
  pendingDirectDepositEnrollment: false,
  employeePayRates: {
    pending: false,
    list: [] as any,
  },
  paycardOffer: false,
  paycardOfferPending: false,
  showPaycardOffer: false,
  voidDrawPending: false,
  declineDrawPending: false,
};

export const fetchEmployeesOffers = createAsyncThunk(
  'employees/offers',
  async (params: FetchEmployeeOfferParamsType, { getState, rejectWithValue }): Promise<any> => {
    const { organizationID, employeeID } = params;
    const storeState = getState() as RootState;

    try {
      const result = await fetchEmployeesOffersRequest(storeState.user.accessToken, organizationID, employeeID);
      return result;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchEmployeeOfferById = createAsyncThunk(
  'employees/offer/id',
  async (params: { offerID: string | undefined }, { getState, rejectWithValue }): Promise<any> => {
    const { offerID } = params;
    const storeState = getState() as RootState;

    try {
      const result = await fetchEmployeesOfferByIdRequest(storeState.user.accessToken, offerID);
      return result;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchEmployeesTPOOffers = createAsyncThunk(
  'employees/tpo-offers',
  async (params: FetchEmployeeOfferParamsType, { getState, rejectWithValue }): Promise<any> => {
    const { organizationID, employeeID } = params;
    const storeState = getState() as RootState;

    try {
      const result = await fetchEmployeesTPOOffersRequest(storeState.user.accessToken, organizationID, employeeID);
      return result;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchFilteredEmployees = createAsyncThunk(
  'employees/filteredSearch',
  async (params: SearchOrganizationEmploymentsParamsType, { getState, rejectWithValue }): Promise<any> => {
    const { organizationID, filter } = params;
    const storeState = getState() as RootState;

    try {
      const result = await searchEmployeesRequest(storeState.user.accessToken, organizationID, filter);
      return result;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const searchEmployees = createAsyncThunk(
  'employees/search',
  async (params: SearchOrganizationEmploymentsParamsType, { getState, rejectWithValue }): Promise<any> => {
    const { organizationID, filter } = params;
    const storeState = getState() as RootState;

    try {
      return await searchEmployeesRequest(storeState.user.accessToken, organizationID, filter);
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchEmployeeConfigs = createAsyncThunk(
  'employees/configs',
  async (params: GetEmployeeConfigsParamsType, { getState, rejectWithValue }): Promise<any> => {
    const { organizationID, employeeID } = params;
    const storeState = getState() as RootState;

    try {
      const result = await fetchEmployeeConfigsRequest(storeState.user.accessToken, organizationID, employeeID);
      return result?.values;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchEmployee = createAsyncThunk(
  'employees/ID',
  async (params: GetEmployeeConfigsParamsType, { dispatch, getState, rejectWithValue }): Promise<any> => {
    const { organizationID, employeeID } = params;
    const storeState = getState() as RootState;

    try {
      const result = await fetchEmployeeRequest(storeState.user.accessToken, organizationID, employeeID);
      const { workerId } = result;

      await dispatch(fetchEmployeeConfigs({ organizationID, employeeID }));

      const intersectionOfUnits = storeState.user.accessUnits.filter(
        (unit) => [AccessUnit.FBOManager, AccessUnit.FBOReader].includes(unit),
      );

      const fuegoAdminUnit = storeState.user.accessUnits.filter(
        (unit) => [AccessUnit.EWAManager].includes(unit),
      );

      if (workerId && intersectionOfUnits.length > 0) {
        await dispatch(fetchWorkerAccountExternal({ workerID: workerId }));
      }

      if (workerId && fuegoAdminUnit.length > 0) {
        await dispatch(fetchWorker({ workerID: workerId }));
      }

      return result;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchEmployeeDetailsEWADraws = createAsyncThunk(
  'employees/ewa-draws',
  async (params: FetchEmployeeDetailsDrawsParamsType, { getState, rejectWithValue }): Promise<any> => {
    const { employeeID, filter } = params;
    const storeState = getState() as RootState;

    try {
      return await fetchEmployeeDrawsRequest(storeState.user.accessToken, employeeID, filter);
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchEmployeeDetailsTPODraws = createAsyncThunk(
  'employees/tpo-draws',
  async (params: FetchEmployeeDetailsDrawsParamsType, { getState, rejectWithValue }): Promise<any> => {
    const { employeeID, filter } = params;
    const storeState = getState() as RootState;

    try {
      return await fetchEmployeeDrawsRequest(storeState.user.accessToken, employeeID, filter);
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const setPrimaryAssignmentForEmployee = createAsyncThunk(
  'employees/setPrimaryAssignmentForEmployee',
  async (params: AssignEmployeesParamsType, { getState, rejectWithValue }): Promise<any> => {
    const { organizationID, data } = params;
    const storeState = getState() as RootState;

    try {
      const linkResult = await editEmploymentWorkersRequest(storeState.user.accessToken, organizationID, data);
      return linkResult;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const saveZendeskTicket = createAsyncThunk(
  'employees/saveZendeskTicket',
  async (params: ZendeskTicketParamsType, { dispatch, getState, rejectWithValue }): Promise<any> => {
    const { drawID, data } = params;
    const storeState = getState() as RootState;

    try {
      const result = await saveZendeskTicketRequest(storeState.user.accessToken, drawID, data);
      storeState?.employees?.employeeDetails?.details?.id
        && dispatch(fetchEmployeeDetailsEWADraws({
          employeeID: storeState.employees.employeeDetails.details.id.toString(),
          filter: {
            pageNumber: storeState.employees.employeeEWADraws.pageNumber.toString(),
            type: 'EWA',
          },
        }));

      return result?.values;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchEmploymentPayrollPeriods = createAsyncThunk(
  'employees/payrollPeriods',
  async (params: FetchByEmploymentPayrollPeriodParamsType, { getState, rejectWithValue }): Promise<any> => {
    const { payrollPeriodID, pageNumber = '1' } = params;
    const storeState = getState() as RootState;

    try {
      return await fetchEmploymentPayrollPeriodsRequest(storeState.user.accessToken, payrollPeriodID, pageNumber);
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const createDeductionBatches = createAsyncThunk(
  'employees/createDeductionBatches',
  async (params: CreateBatchesPropsType, { dispatch, getState, rejectWithValue }): Promise<any> => {
    const { organizationID, payrollPeriodID, data } = params;
    const storeState = getState() as RootState;

    try {
      const result = await createDeductionBatchesRequest(storeState.user.accessToken, organizationID, payrollPeriodID, data);

      dispatch(fetchEmploymentPayrollPeriods({
        payrollPeriodID: payrollPeriodID?.toString(),
      }));

      return result;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const editEmployeeDetails = createAsyncThunk(
  'employees/ID/edit',
  async (params: EditEmployeeDetailsParamsType, { getState, rejectWithValue }): Promise<any> => {
    const {
      organization, employeeID, employeeDetailsData, employeeConfigsData,
    } = params;
    const storeState = getState() as RootState;

    try {
      return employeeID && organization?.id && await Promise.all([
        editEmployeeDetailsRequest(storeState.user.accessToken, organization?.id, employeeID, employeeDetailsData),
        editEmployeeConfigsRequest(storeState.user.accessToken, organization?.id, employeeID, employeeConfigsData),
      ]);
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const editEmployeeDetailsProfile = createAsyncThunk(
  'employee/profile/edit',
  async (params: EditEmployeeDetailsProfileParamsType, { getState, rejectWithValue }): Promise<any> => {
    const {
      organization, employeeID, employeeDetailsData,
    } = params;
    const storeState = getState() as RootState;
    try {
      const result = await editEmployeeDetailsRequest(storeState.user.accessToken, organization?.id, employeeID, employeeDetailsData);
      return result?.values;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const editEmployeeDetailsOnDemandPay = createAsyncThunk(
  'employees/ID/edit',
  async (params: EditEmployeeDetailsParamsType, { getState, rejectWithValue }): Promise<any> => {
    const {
      organization, employeeID, employeeDetailsData, employeeConfigsData,
    } = params;
    const storeState = getState() as RootState;

    try {
      return employeeID && organization?.id && await Promise.all([
        editEmployeeDetailsRequest(storeState.user.accessToken, organization?.id, employeeID, employeeDetailsData),
        editEmployeeConfigsRequest(storeState.user.accessToken, organization?.id, employeeID, employeeConfigsData),
      ]);
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

// TODO: fix this nonsense of result [0]
export const fetchEmployeesWithConfigsLocationsAndGroups = createAsyncThunk(
  'employees/fetchEmployeesWithConfigsLocationsAndGroups',
  async (params: OrganizationFormPropsType, { getState, rejectWithValue }): Promise<any> => {
    const { organizationID } = params;
    const storeState = getState() as RootState;

    try {
      const result = await Promise.all([
        Promise.resolve(() => ['']),
        fetchDefaultConfigRequest(storeState.user.accessToken),
        fetchLocationsRequest(storeState.user.accessToken, organizationID),
        fetchPayGroupsRequest(storeState.user.accessToken, organizationID),
      ]);
      return [
        result[0],
        result[1],
        result[2]?.values,
        result[3]?.values,
      ];
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const assignEmployeesToEmployment = createAsyncThunk(
  'employees/assignEmployee',
  async (params: AssignEmployeesParamsType, { getState, rejectWithValue, dispatch }): Promise<any> => {
    const { organizationID, data } = params;
    const storeState = getState() as RootState;

    try {
      const linkResult = await editEmploymentWorkersRequest(storeState.user.accessToken, organizationID, data);
      dispatch(fetchFilteredEmployees({ organizationID, filter: { pageNumber: '1' } }));
      const assignmentsData = {
        ...data,
        operation: OperationMode.ASSIGNMENT_UPDATE,
      };
      await editEmploymentWorkersRequest(storeState.user.accessToken, organizationID, assignmentsData);
      return linkResult;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchWorkerEmploymentLinks = createAsyncThunk(
  'employees/workerEmploymentLinks',
  async (params: WorkerEmploymentLinkParamsType, { getState, rejectWithValue }): Promise<any> => {
    const storeState = getState() as RootState;

    try {
      return await fetchWorkerEmploymentLinksRequest(storeState.user.accessToken, params);
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);
export const createACF = createAsyncThunk(
  'employees/acf/create',
  async (params: CreateACFParamTypes, { getState, rejectWithValue, dispatch }): Promise<any> => {
    const {
      employmentId, drawId, idempotencyKey, data,
    } = params;
    const storeState = getState() as RootState;

    try {
      const result = await createACFRequest(storeState.user.accessToken, drawId, employmentId, String(idempotencyKey), data);
      dispatch(fetchEmployeeDetailsEWADraws({
        employeeID: employmentId,
        filter: {
          pageNumber: '1',
          type: 'EWA',
        },
      }));
      return result;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const createBadDebt = createAsyncThunk(
  'employees/badDebt/create',
  async (params: CreateACFParamTypes, { getState, rejectWithValue, dispatch }): Promise<any> => {
    const {
      employmentId, drawId, data,
    } = params;
    const storeState = getState() as RootState;

    try {
      const result = await createBadDebtRequest(storeState.user.accessToken, drawId, employmentId, data);
      dispatch(fetchEmployeeDetailsEWADraws({
        employeeID: employmentId,
        filter: {
          pageNumber: '1',
          type: 'EWA',
        },
      }));
      return result;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const generateDirectDepositReportReport = createAsyncThunk(
  'employees/direct-deposit-report',
  async (params: GenerateDirectDepositReportParamsType, { getState, rejectWithValue }): Promise<any> => {
    const {
      organizationID,
      fromDate,
      toDate,
    } = params;
    const storeState = getState() as RootState;

    try {
      return {
        id: `organization_${organizationID}-direct-deposit-report`,
        csv: await generateDirectDepositReportRequest(storeState.user.accessToken, organizationID, fromDate, toDate),
      };
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchDirectDepositEnrollmentByWorkerId = createAsyncThunk(
  'employees/directDeposit/enrollment',
  async (params: GetDirectDepositEnrollmentByWorkerIdType, { getState, rejectWithValue }): Promise<any> => {
    const { organizationID, workerID } = params;
    const storeState = getState() as RootState;

    try {
      const result = await fetchEnrollmentByWorkerIdRequest(storeState.user.accessToken, organizationID, workerID);
      return result;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchEmploymentPayRates = createAsyncThunk(
  'employee/payRates',
  async (params: GetEmployeePayRatesParamsType, { getState, rejectWithValue }): Promise<any> => {
    const { organizationID, employeeID } = params;
    const storeState = getState() as RootState;

    try {
      return await fetchEmploymentPayRatesRequest(storeState.user.accessToken, organizationID, employeeID);
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchPaycardOffer = createAsyncThunk(
  'employees/paycardOffer',
  async (params: FetchEmployeePaycardOfferParamsType, { getState, rejectWithValue }): Promise<any> => {
    const { organizationID, workerID } = params;
    const storeState = getState() as RootState;

    try {
      return await fetchWorkerPaycardOfferRequest(
        storeState.user.accessToken,
        workerID,
        organizationID,
      );
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const changeWorkerPaycardOffer = createAsyncThunk(
  'employees/paycardOffer/change',
  async (params: ChangeEmployeePaycardOfferParamsType, { getState, rejectWithValue }): Promise<any> => {
    const { organizationID, workerID, allowed } = params;
    const storeState = getState() as RootState;

    try {
      return await changeWorkerPaycardOfferRequest(
        storeState.user.accessToken,
        workerID,
        organizationID,
        allowed,
      );
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const editEmploymentPayRates = createAsyncThunk(
  'employee/payRates/edit',
  async (params: EditEmploymentPayRatesParamsType, { getState, rejectWithValue }): Promise<any> => {
    const {
      organizationID, employmentID, data,
    } = params;
    const storeState = getState() as RootState;
    try {
      const result = await editEmploymentPayRatesRequest(storeState.user.accessToken, organizationID, employmentID, data);
      return result?.values;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const declineDraw = createAsyncThunk(
  'employee/ledgers/decline',
  async (params: DeclineDrawRequestParamsType, { getState, rejectWithValue }): Promise<any> => {
    const {
      ledgerID, employmentID,
    } = params;
    const storeState = getState() as RootState;
    try {
      const result = await declineDrawRequest(storeState.user.accessToken, employmentID, ledgerID);
      return result?.values;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const voidDraw = createAsyncThunk(
  'employee/ledgers/void',
  async (params: VoidDrawRequestParamsType, { getState, rejectWithValue }): Promise<any> => {
    const {
      ledgerID, employmentID,
    } = params;
    const storeState = getState() as RootState;
    try {
      const result = await voidDrawRequest(storeState.user.accessToken, employmentID, ledgerID);
      return result?.values;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

const employeesSlice = createSlice({
  name: 'employees',
  initialState,
  reducers: {
    resetSearchEmployees: (state) => {
      state.searchResults = initialState.searchResults;
    },
    resetEmployees: (state) => {
      state.list = initialState.list;
    },
    resetEmployment: (state) => {
      state.employment = initialState.employment;
    },
    resetEmployeeEWADraws: (state) => {
      state.employeeEWADraws = initialState.employeeEWADraws;
    },
    resetEmployeeTPODraws: (state) => {
      state.employeeTPODraws = initialState.employeeTPODraws;
    },
    resetEmployeeOffers: (state) => {
      state.employeeOffers = initialState.employeeOffers;
    },
    resetEmployeeOffersById: (state) => {
      state.employeeOffers.offer = initialState.employeeOffers.offer;
    },
    setEmployeeDetails: (state, action) => {
      state.employeeDetails.details = action.payload;
    },
    resetEmployeeDetails: (state) => {
      state.employeeDetails.details = initialState.employeeDetails.details;
    },
    setEmployeesPage: (state, action) => {
      state.pageNumber = action.payload;
    },
    setEmployeesPageSize: (state, action) => {
      state.specifiedPageSize = action.payload;
    },
    setSearchPattern: (state, action) => {
      state.employeeSearch.searchPattern = action.payload;
    },
    setSearchEnrollmentStatus: (state, action) => {
      state.employeeSearch.searchEnrollmentStatus = action.payload;
    },
    setSearchEmploymentStatus: (state, action) => {
      state.employeeSearch.searchEmploymentStatus = action.payload;
    },
    setSearchPayGroup: (state, action) => {
      state.employeeSearch.searchPayGroup = action.payload;
    },
    setSearchLocation: (state, action) => {
      state.employeeSearch.searchLocation = action.payload;
    },
    setSearchPayrollNumber: (state, action) => {
      state.employeeSearch.searchPayrollNumber = action.payload;
    },
    resetEmployeeSearch: (state) => {
      state.employeeSearch = initialState.employeeSearch;
    },
    resetDirectDepositReportData: (state) => {
      state.directDepositReportData.file = initialState.directDepositReportData.file;
    },
    resetPayRates: (state) => {
      state.employeePayRates = initialState.employeePayRates;
    },
    setEmployeeTypeChanged: (state, action) => {
      state.employeeDetails.employeeTypeChanged = action.payload;
    },
    setFilterCriteria: (state, action) => {
      state.employeeSearch.filterCriteria = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchWorkerEmploymentLinks.pending, (state) => {
      state.workerLinking.list = initialState.workerLinking.list;
      state.workerLinking.pendingList = true;
    });

    builder.addCase(fetchWorkerEmploymentLinks.fulfilled, (state, { payload }) => {
      state.workerLinking.list = payload.values;
      state.workerLinking.pageNumber = payload.pageNumber;
      state.workerLinking.pageSize = payload.pageSize;
      state.workerLinking.totalSize = payload.totalSize;
      state.workerLinking.numPages = (Math.ceil(payload.totalSize / initialState.workerLinking.pageSize));
      state.workerLinking.pendingList = false;
    });

    builder.addCase(fetchWorkerEmploymentLinks.rejected, (state) => {
      state.workerLinking.list = initialState.workerLinking.list;
      state.workerLinking.pendingList = false;
    });

    builder.addCase(fetchEmployeesOffers.pending, (state) => {
      state.employeeOffers.generatedOffer = initialState.employeeOffers.generatedOffer;
      state.employeeOffers.areFetched = initialState.employeeOffers.areFetched;
      state.employeeOffers.pendingList = true;
    });

    builder.addCase(fetchEmployeesOffers.fulfilled, (state, action) => {
      state.employeeOffers.generatedOffer = action.payload;
      state.employeeOffers.areFetched = true;
      state.employeeOffers.pendingList = false;
    });

    builder.addCase(fetchEmployeesOffers.rejected, (state) => {
      state.employeeOffers.areFetched = initialState.employeeOffers.areFetched;
      state.employeeOffers.pendingList = false;
    });

    builder.addCase(fetchEmployeeOfferById.pending, (state) => {
      state.employeeOffers.offer = initialState.employeeOffers.offer;
      state.employeeOffers.areFetched = initialState.employeeOffers.areFetched;
      state.employeeOffers.pendingList = true;
    });

    builder.addCase(fetchEmployeeOfferById.fulfilled, (state, action) => {
      state.employeeOffers.offer = action.payload;
      state.employeeOffers.areFetched = true;
      state.employeeOffers.pendingList = false;
    });

    builder.addCase(fetchEmployeeOfferById.rejected, (state) => {
      state.employeeOffers.areFetched = initialState.employeeOffers.areFetched;
      state.employeeOffers.pendingList = false;
    });

    builder.addCase(fetchEmployeesTPOOffers.pending, (state) => {
      state.employeeTPOOffers.generatedOffer = initialState.employeeTPOOffers.generatedOffer;
      state.employeeTPOOffers.areFetched = initialState.employeeTPOOffers.areFetched;
      state.employeeTPOOffers.pendingList = true;
    });

    builder.addCase(fetchEmployeesTPOOffers.fulfilled, (state, action) => {
      state.employeeTPOOffers.generatedOffer = action.payload;
      state.employeeTPOOffers.areFetched = true;
      state.employeeTPOOffers.pendingList = false;
    });

    builder.addCase(fetchEmployeesTPOOffers.rejected, (state) => {
      state.employeeTPOOffers.areFetched = initialState.employeeTPOOffers.areFetched;
      state.employeeTPOOffers.pendingList = false;
    });

    builder.addCase(fetchFilteredEmployees.pending, (state) => {
      state.list = initialState.list;
      state.pendingList = true;
      state.fetchEmployeesFailed = initialState.fetchEmployeesFailed;
    });

    builder.addCase(fetchFilteredEmployees.fulfilled, (state, action) => {
      state.fetchEmployeesFailed = false;
      state.pendingList = false;
      state.list = action.payload.values;
      state.pageNumber = action.payload.pageNumber;
      state.pageSize = action.payload.pageSize;
      state.totalSize = action.payload.totalSize;
      state.numPages = (Math.ceil(action.payload.totalSize / state.specifiedPageSize));
    });

    builder.addCase(fetchFilteredEmployees.rejected, (state) => {
      state.pendingList = false;
      state.fetchEmployeesFailed = true;
    });

    builder.addCase(searchEmployees.pending, (state) => {
      state.searchResults = initialState.searchResults;
      state.pendingSearchList = true;
    });

    builder.addCase(searchEmployees.fulfilled, (state, action) => {
      state.searchResults = action.payload.values;
      state.searchPageNumber = action.payload.pageNumber;
      state.searchPageSize = action.payload.pageSize;
      state.searchTotalSize = action.payload.totalSize;
      state.searchNumPages = (Math.ceil(action.payload.totalSize / initialState.searchPageSize));
      state.pendingSearchList = false;
    });

    builder.addCase(searchEmployees.rejected, (state) => {
      state.pendingSearchList = false;
    });

    builder.addCase(fetchEmployee.pending, (state) => {
      state.employeeDetails.pending = true;
    });

    builder.addCase(fetchEmployee.fulfilled, (state, action) => {
      state.employeeDetails.details = action.payload;
      state.employeeDetails.pending = false;
    });

    builder.addCase(fetchEmployee.rejected, (state) => {
      state.employeeDetails.pending = false;
    });

    builder.addCase(fetchEmployeeConfigs.pending, (state) => {
      state.employeeDetails.configs = initialState.employeeDetails.configs;
    });

    builder.addCase(fetchEmployeeConfigs.fulfilled, (state, action) => {
      state.employeeDetails.configs = action.payload;
    });

    builder.addCase(fetchEmployeeDetailsEWADraws.pending, (state) => {
      state.employeeEWADraws.list = initialState.employeeEWADraws.list;
      state.employeeEWADraws.pendingList = true;
    });

    builder.addCase(fetchEmployeeDetailsEWADraws.fulfilled, (state, action) => {
      state.employeeEWADraws.list = action.payload.values;
      state.employeeEWADraws.pageSize = action.payload.pageSize;
      state.employeeEWADraws.pageNumber = action.payload.pageNumber;
      state.employeeEWADraws.totalSize = action.payload.totalSize;
      state.employeeEWADraws.numPages = (Math.ceil(action.payload.totalSize / initialState.employeeEWADraws.pageSize));
      state.employeeEWADraws.pendingList = false;
      state.employeeEWADraws.areListFetched = true;
    });

    builder.addCase(fetchEmployeeDetailsEWADraws.rejected, (state) => {
      state.employeeEWADraws.pendingList = false;
    });

    builder.addCase(fetchEmployeeDetailsTPODraws.pending, (state) => {
      state.employeeTPODraws.list = initialState.employeeTPODraws.list;
      state.employeeTPODraws.pendingList = true;
    });

    builder.addCase(fetchEmployeeDetailsTPODraws.fulfilled, (state, action) => {
      state.employeeTPODraws.list = action.payload.values;
      state.employeeTPODraws.pageSize = action.payload.pageSize;
      state.employeeTPODraws.pageNumber = action.payload.pageNumber;
      state.employeeTPODraws.totalSize = action.payload.totalSize;
      state.employeeTPODraws.numPages = (Math.ceil(action.payload.totalSize / initialState.employeeTPODraws.pageSize));
      state.employeeTPODraws.pendingList = false;
      state.employeeTPODraws.areListFetched = true;
    });

    builder.addCase(fetchEmployeeDetailsTPODraws.rejected, (state) => {
      state.employeeTPODraws.pendingList = false;
    });

    builder.addCase(editEmployeeDetailsProfile.pending, (state) => {
      state.employeeDetails.configsAndDetailsSaved = false;
      state.employeeDetails.pending = true;
    });

    builder.addCase(editEmployeeDetailsProfile.fulfilled, (state, action) => {
      if (action.payload) {
        const [details, configs] = action.payload;

        state.employeeDetails.details = { ...state.employeeDetails.details, ...details };
        state.employeeDetails.configs = configs.values;
      }

      state.employeeDetails.configsAndDetailsSaved = true;
      state.employeeDetails.pending = false;
    });

    builder.addCase(editEmployeeDetailsProfile.rejected, (state) => {
      state.employeeDetails.configsAndDetailsSaved = false;
      state.employeeDetails.pending = false;
    });

    builder.addCase(editEmployeeDetailsOnDemandPay.pending, (state) => {
      state.employeeDetails.configsAndDetailsSaved = false;
      state.employeeDetails.pending = true;
    });

    builder.addCase(editEmployeeDetailsOnDemandPay.fulfilled, (state, action) => {
      if (action.payload) {
        const [details, configs] = action.payload;

        state.employeeDetails.details = { ...state.employeeDetails.details, ...details };
        state.employeeDetails.configs = configs.values;
      }

      state.employeeDetails.configsAndDetailsSaved = true;
      state.employeeDetails.pending = false;
    });

    builder.addCase(editEmployeeDetailsOnDemandPay.rejected, (state) => {
      state.employeeDetails.configsAndDetailsSaved = false;
      state.employeeDetails.pending = false;
    });

    builder.addCase(setPrimaryAssignmentForEmployee.pending, (state) => {
      state.employeeDetails.pending = true;
    });

    builder.addCase(fetchEmploymentPayrollPeriods.pending, (state) => {
      state.employment.payrollPeriods = initialState.employment.payrollPeriods;
      state.employment.pending = true;
    });

    builder.addCase(fetchEmploymentPayrollPeriods.fulfilled, (state, action) => {
      state.employment.payrollPeriods = action.payload.values;
      state.employment.pageNumber = action.payload.pageNumber;
      state.employment.pageSize = action.payload.pageSize;
      state.employment.totalSize = action.payload.totalSize;
      state.employment.numPages = (Math.ceil(action.payload.totalSize / initialState.employment.pageSize));
      state.employment.pending = false;
    });

    builder.addCase(fetchEmploymentPayrollPeriods.rejected, (state) => {
      state.employment.pending = false;
    });

    builder.addCase(createDeductionBatches.pending, (state) => {
      state.employment.pending = true;
    });

    builder.addCase(createDeductionBatches.fulfilled, (state) => {
      state.employment.deductionBatchesSaved = true;
      state.employment.pending = false;
    });

    builder.addCase(createDeductionBatches.rejected, (state) => {
      state.employment.deductionBatchesSaved = false;
      state.employment.pending = false;
    });

    builder.addCase(saveZendeskTicket.pending, (state) => {
      state.employeeEWADraws.zendeskTicketSaved = false;
      state.employeeEWADraws.pendingZendeskTicketForm = true;
    });

    builder.addCase(saveZendeskTicket.fulfilled, (state) => {
      state.employeeEWADraws.zendeskTicketSaved = true;
      state.employeeEWADraws.pendingZendeskTicketForm = false;
    });

    builder.addCase(saveZendeskTicket.rejected, (state) => {
      state.employeeEWADraws.zendeskTicketSaved = false;
      state.employeeEWADraws.pendingZendeskTicketForm = false;
    });

    builder.addCase(assignEmployeesToEmployment.pending, (state) => {
      state.pendingSearchList = true;
    });

    builder.addCase(assignEmployeesToEmployment.fulfilled, (state) => {
      state.pendingSearchList = false;
    });

    builder.addCase(assignEmployeesToEmployment.rejected, (state) => {
      state.pendingSearchList = false;
    });

    builder.addCase(resetLinkEmploymentsFormEvent, (state) => {
      state.searchResults = initialState.searchResults;
    });

    builder.addCase(resetEmployeeDetailsWorkersDrawsAndOffers, (state) => {
      state.employeeDetails = initialState.employeeDetails;
      state.employeeEWADraws = initialState.employeeEWADraws;
      state.employeeTPODraws = initialState.employeeTPODraws;
      state.employeeTPOOffers = initialState.employeeTPOOffers;
      state.employeeOffers = initialState.employeeOffers;
    });

    builder.addCase(generateDirectDepositReportReport.pending, (state) => {
      state.directDepositReportData.pending = true;
    });

    builder.addCase(generateDirectDepositReportReport.fulfilled, (state, action) => {
      state.directDepositReportData.file = {
        id: action.payload.id,
        csv: action.payload.csv,
      };
      state.directDepositReportData.pending = false;
    });

    builder.addCase(generateDirectDepositReportReport.rejected, (state) => {
      state.directDepositReportData.file = initialState.directDepositReportData.file;
      state.directDepositReportData.pending = false;
    });

    builder.addCase(fetchDirectDepositEnrollmentByWorkerId.pending, (state) => {
      state.directDepositEnrollment = initialState.directDepositEnrollment;
      state.pendingDirectDepositEnrollment = true;
    });

    builder.addCase(fetchDirectDepositEnrollmentByWorkerId.fulfilled, (state, action) => {
      state.directDepositEnrollment = action.payload;
      state.pendingDirectDepositEnrollment = false;
    });

    builder.addCase(fetchDirectDepositEnrollmentByWorkerId.rejected, (state) => {
      state.pendingDirectDepositEnrollment = false;
    });

    builder.addCase(setLoggedOrganization, (state) => {
      state.employeeDetails = initialState.employeeDetails;
    });

    builder.addCase(fetchEmploymentPayRates.pending, (state) => {
      state.employeePayRates.list = initialState.employeePayRates.list;
      state.employeePayRates.pending = true;
    });

    builder.addCase(fetchEmploymentPayRates.fulfilled, (state, action) => {
      state.employeePayRates.list = action.payload;
      state.employeePayRates.pending = false;
    });

    builder.addCase(fetchEmploymentPayRates.rejected, (state) => {
      state.employeePayRates.list = initialState.employeePayRates.list;
      state.employeePayRates.pending = false;
    });

    builder.addCase(fetchPaycardOffer.pending, (state) => {
      state.paycardOfferPending = true;
      state.showPaycardOffer = initialState.showPaycardOffer;
    });

    builder.addCase(fetchPaycardOffer.fulfilled, (state, action) => {
      state.paycardOffer = action.payload.allowed;
      state.showPaycardOffer = true;
      state.paycardOfferPending = false;
    });

    builder.addCase(fetchPaycardOffer.rejected, (state) => {
      state.showPaycardOffer = false;
      state.paycardOfferPending = false;
    });

    builder.addCase(changeWorkerPaycardOffer.fulfilled, (state) => {
      state.paycardOffer = !state.paycardOffer;
    });

    builder.addCase(voidDraw.pending, (state) => {
      state.voidDrawPending = true;
    });

    builder.addCase(voidDraw.fulfilled, (state) => {
      state.voidDrawPending = false;
    });

    builder.addCase(voidDraw.rejected, (state) => {
      state.voidDrawPending = false;
    });

    builder.addCase(declineDraw.pending, (state) => {
      state.declineDrawPending = true;
    });

    builder.addCase(declineDraw.fulfilled, (state) => {
      state.voidDrawPending = false;
    });

    builder.addCase(declineDraw.rejected, (state) => {
      state.voidDrawPending = false;
    });
  },
});

export const {
  resetSearchEmployees,
  resetEmployees,
  resetEmployment,
  resetEmployeeEWADraws,
  resetEmployeeTPODraws,
  resetEmployeeOffers,
  resetEmployeeOffersById,
  setEmployeeDetails,
  setEmployeesPage,
  setSearchPattern,
  setSearchPayrollNumber,
  setSearchEmploymentStatus,
  setSearchEnrollmentStatus,
  setSearchPayGroup,
  setSearchLocation,
  setFilterCriteria,
  resetEmployeeSearch,
  resetDirectDepositReportData,
  resetPayRates,
  resetEmployeeDetails,
  setEmployeesPageSize,
  setEmployeeTypeChanged,
} = employeesSlice.actions;

export const employeesSelector = (state: RootState): Employee[] => state.employees.list;
export const employeesFetchListFailedSelector = (state: RootState): boolean => state.employees.fetchEmployeesFailed;
export const employeesPendingSearchListSelector = (state: RootState): boolean => state.employees.pendingSearchList;
export const employeesPendingListSelector = (state: RootState): boolean => state.employees.pendingList;
export const employeesEWADrawsPendingListSelector = (state: RootState): boolean => state.employees.employeeEWADraws.pendingList;
export const employeesEWADrawsAreListFetchedSelector = (state: RootState): boolean => state.employees.employeeEWADraws.areListFetched;
export const employeesTPODrawsPendingListSelector = (state: RootState): boolean => state.employees.employeeTPODraws.pendingList;
export const employeesTPODrawsAreListFetchedSelector = (state: RootState): boolean => state.employees.employeeTPODraws.areListFetched;
export const employeesOffersPendingListSelector = (state: RootState): boolean => state.employees.employeeOffers.pendingList;
export const employeeDetailsSelector = (state: RootState): EmployeeResponseType | undefined => state.employees.employeeDetails.details;
export const employeeDetailsOffersSelector = (state: RootState): Offer | undefined => state.employees.employeeOffers.offer;
export const employeeDetailsGeneratedOffersSelector = (state: RootState): Offer | undefined => state.employees.employeeOffers.generatedOffer;
export const employeeOffersAreFetchedSelector = (state: RootState): boolean => state.employees.employeeOffers.areFetched;
export const employeeDetailsPendingSelector = (state: RootState): boolean => state.employees.employeeDetails.pending;
export const employeeDetailsConfigsAndDetailsSavedSelector = (state: RootState)
  : boolean => state.employees.employeeDetails.configsAndDetailsSaved;
export const employeeConfigsSelector = (state: RootState): OrganizationConfigResponseType[] | null => state.employees.employeeDetails.configs;
export const employeeEWADrawsSelector = (state: RootState): Draw[] => state.employees.employeeEWADraws.list;
export const employeesEWADrawsPageSizeSelector = (state: RootState) => state.employees.employeeEWADraws.pageSize;
export const employeesEWADrawsNumPagesSelector = (state: RootState) => state.employees.employeeEWADraws.numPages;
export const employeesEWADrawsPageNumberSelector = (state: RootState) => state.employees.employeeEWADraws.pageNumber;
export const employeesEWADrawsTotalSizeSelector = (state: RootState) => state.employees.employeeEWADraws.totalSize;
export const employeeTPODrawsSelector = (state: RootState): Draw[] => state.employees.employeeTPODraws.list;
export const employeesTPODrawsPageSizeSelector = (state: RootState) => state.employees.employeeTPODraws.pageSize;
export const employeesTPODrawsNumPagesSelector = (state: RootState) => state.employees.employeeTPODraws.numPages;
export const employeesTPODrawsPageNumberSelector = (state: RootState) => state.employees.employeeTPODraws.pageNumber;
export const employeesTPODrawsTotalSizeSelector = (state: RootState) => state.employees.employeeTPODraws.totalSize;
export const employmentDeductionBatchesSavedSelector = (state: RootState): boolean => state.employees.employment.deductionBatchesSaved;
export const employmentZendeskTicketSavedSelector = (state: RootState): boolean => state.employees.employeeEWADraws.zendeskTicketSaved;
export const employmentZendeskTicketFormPendingSelector = (state: RootState): boolean => state.employees.employeeEWADraws.pendingZendeskTicketForm;
export const employmentPendingSelector = (state: RootState): boolean => state.employees.employment.pending;
export const employeesSearchResultsSelector = (state: RootState): Employee[] => state.employees.searchResults;
export const employeesSearchPageSizeSelector = (state: RootState): number => state.employees.searchPageSize;
export const employeesSearchNumPagesSelector = (state: RootState): number => state.employees.searchNumPages;
export const employeesSearchPageNumberSelector = (state: RootState): number => state.employees.searchPageNumber;
export const employeesSearchTotalSizeSelector = (state: RootState): number => state.employees.searchTotalSize;
export const employmentPayrollPeriodsSelector = (state: RootState): EmploymentPayroll[] => state.employees.employment.payrollPeriods;
export const employeesPageNumberSelector = (state: RootState) => state.employees.pageNumber;
export const employeesPageSizeSelector = (state: RootState) => state.employees.pageSize;
export const employeesTotalSizeSelector = (state: RootState) => state.employees.totalSize;
export const employeesNumPagesSelector = (state: RootState) => state.employees.numPages;
export const employmentPageNumberSelector = (state: RootState) => state.employees.employment.pageNumber;
export const employmentPageSizeSelector = (state: RootState) => state.employees.employment.pageSize;
export const employmentNumPagesSelector = (state: RootState) => state.employees.employment.numPages;
export const employmentTotalSizeSelector = (state: RootState) => state.employees.employment.totalSize;
export const employmentWorkerLinkingTotalSizeSelector = (state: RootState) => state.employees.workerLinking.totalSize;
export const employmentWorkerLinkingPageSizeSelector = (state: RootState) => state.employees.workerLinking.pageSize;
export const employmentWorkerLinkingNumPagesSelector = (state: RootState) => state.employees.workerLinking.numPages;
export const employmentWorkerLinkingPageNumberSelector = (state: RootState) => state.employees.workerLinking.pageNumber;
export const employmentWorkerLinkingListSelector = (state: RootState) => state.employees.workerLinking.list;
export const employmentWorkerLinkingPendingListSelector = (state: RootState) => state.employees.workerLinking.pendingList;
export const employeesSearchPatternSelector = (state: RootState) => state.employees.employeeSearch.searchPattern;
export const employeesSearchEnrollmentStatusSelector = (state: RootState) => state.employees.employeeSearch.searchEnrollmentStatus;
export const employeesSearchEmploymentStatusSelector = (state: RootState) => state.employees.employeeSearch.searchEmploymentStatus;
export const employeesSearchPayGroupSelector = (state: RootState) => state.employees.employeeSearch.searchPayGroup;
export const employeesSearchPayrollNumberSelector = (state: RootState) => state.employees.employeeSearch.searchPayrollNumber;
export const employeesFilterCriteriaSelector = (state: RootState) => state.employees.employeeSearch.filterCriteria;
export const employeesSearchLocationSelector = (state: RootState) => state.employees.employeeSearch.searchLocation;
export const directDepositReportDataSelector = (state: RootState): DirectDepositReportData | null | undefined => state.employees.directDepositReportData.file;
export const directDepositReportDataPendingSelector = (state: RootState): boolean => state.employees.directDepositReportData.pending;
export const employeesTPOOffersPendingListSelector = (state: RootState): boolean => state.employees.employeeTPOOffers.pendingList;
export const employeeTPODetailsGeneratedOffersSelector = (state: RootState): TPOOffer | undefined => state.employees.employeeTPOOffers.generatedOffer;
export const employeeTPOOffersAreFetchedSelector = (state: RootState): boolean => state.employees.employeeTPOOffers.areFetched;

export const employeeDirectDepositEnrollmentSelector = (state: RootState): DirectDepositEnrollmentType | undefined => state.employees.directDepositEnrollment;
export const employeeDirectDepositEnrollmentPendingSelector = (state: RootState): boolean => state.employees.pendingDirectDepositEnrollment;

export const employmentPayRatesPendingSelector = (state: RootState): boolean => state.employees.employeePayRates.pending;
export const employmentPayRatesListSelector = (state: RootState): PayRatesResponseType => state.employees.employeePayRates.list;

export const paycardOfferSelector = (state: RootState): boolean | undefined => state.employees.paycardOffer;
export const paycardOfferPendingSelector = (state: RootState): boolean => state.employees.paycardOfferPending;
export const showPaycardOfferSelector = (state: RootState): boolean => state.employees.showPaycardOffer;

export const employeeDetailsEmployeeTypeChangedSelector = (state: RootState): boolean => state.employees.employeeDetails.employeeTypeChanged;

export const employeeDrawHistoryVoidDrawPendingSelector = (state: RootState): boolean => state.employees.voidDrawPending;
export const employeeDrawHistoryDeclineDrawPendingSelector = (state: RootState): boolean => state.employees.declineDrawPending;

export default employeesSlice.reducer;
